/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC, useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {PageLink, useLayout} from '../../../_metronic/layout/core'
import {DefaultTitle} from './header/page-title/DefaultTitle'
import {useAuth} from "../../hooks/useAuth";
import {useLocation} from "react-router-dom";
import {PageDataProviderContext} from "../PageDataProvider";

const ToolbarAdmin: FC = () => {
  const {classes} = useLayout()
  const auth = useAuth();
  const storedBreadcrumbs: any = auth.getBreadcrumbs();
  const [breadcrumb, setBreadcrumb] = useState<Array<PageLink>>(storedBreadcrumbs);
  const [title, setTitle] = useState( auth.getTitle());
  const location = useLocation();



  const update = (b: any, t: any) => {
    setBreadcrumb(b);
    setTitle(t);
  }



  useEffect(() => {

    setInterval(() => {
      if(auth.getBreadcrumbsChanged()) {
        auth.setBreadcrumbsChanged('N');
        const storedBreadcrumbs = auth.getBreadcrumbs();
        update(storedBreadcrumbs, auth.getTitle());
      }
    }, 100);
  }, [location.pathname])

  return (
      <PageDataProviderContext.Consumer>
        {({toolbar}) => (
            <>
              <div className='toolbar' id='kt_toolbar'>
              {/* begin::Container */}
              <div
                  id='kt_toolbar_container'
                  className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
              >
                <DefaultTitle breadcrumb={toolbar.breadcrumbs} title={toolbar.title} />

                {/* begin::Actions */}
                {toolbar.bottom !== true && (
                  <div className='d-flex align-items-center py-1'>
                    {toolbar.buttons?.map((item) => {
                      return (item)
                    })}
                  </div>
                )}
                {/* end::Actions */}
              </div>
              {/* end::Container */}
            </div>
            {toolbar.bottom === true && (
              <div className="fixed-bottom">
              <div className="d-flex align-items-center justify-content-end p-4 bg-dark shadow-lg">
                  <div className="d-flex w-100 w-sm-50">
                  {toolbar.buttons?.map((item) => {
                    return (item)
                  })}
                  </div>
              </div>
            </div>
            )}
            </>
        )}
      </PageDataProviderContext.Consumer>
  )
}

export {ToolbarAdmin}
