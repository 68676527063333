import React, {FC} from "react";
import {ApexOptions} from "apexcharts";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import 'moment/locale/pt-br';

interface Props {
    series: Array<any>
}

const ResearchOverviewNPSChart: FC<Props> = ({series}) => {
    var height = 300;
    var labelColor = "#A1A5B7";
    var borderColor = "#E4E6EF";
    var baseColor = "#50CD89";
    var lightColor = "#50CD89";

    var optionsApex: ApexOptions = {
        chart: {
            fontFamily: 'inherit',
            type: 'area',
            height: height,
            toolbar: {
                show: false
            }
        },
        plotOptions: {

        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            colors: [baseColor],
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.4,
                opacityTo: 0,
                stops: [0, 80, 100]
            }
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [baseColor]
        },
        xaxis: {
            categories: series.map((history) => {
                moment.locale('pt-br');
                return moment(history.date).format("DD MMM");
            }),
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false
            },
            tickAmount: 6,
            labels: {
                rotate: 0,
                rotateAlways: true,
                style: {
                    colors: labelColor,
                    fontSize: '12px'
                }
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    color: baseColor,
                    width: 1,
                    dashArray: 3
                }
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px'
                }
            }
        },
        yaxis: {
            tickAmount: 4,
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px'
                },
                formatter: function (val) {
                    return val.toString()
                }
            }
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0
                }
            }
        },
        tooltip: {
            style: {
                fontSize: '12px'
            },
            y: {
                formatter: function (val) {
                    return val.toString()
                }
            }
        },
        colors: [lightColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        markers: {
            strokeColors: baseColor,
            strokeWidth: 3
        }
    };

    return (
        <>
            <ReactApexChart
                options={optionsApex}
                type="area"
                series={[{
                    name: 'NPS',
                    data: series.map((history: any) => {
                        return history.nps;
                    })
                }]}
            />
        </>
    );
}

export default ResearchOverviewNPSChart;