/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Redirect, Switch, Route, useLocation} from 'react-router-dom'
import {MasterLayout} from '../components/layout/MasterLayout'
import {AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {MasterInit} from '../components/layout/MasterInit'
import {useAuth} from "../hooks/useAuth";
import {AdminLayout} from "../components/layout/AdminLayout";
import {FallbackView} from "../partials/FallbackView";
import {UserContext} from "../providers/UserContextProvider";
import {SiteLayout} from "../components/layout/SiteLayout";

const Routes: FC = () => {
    const {isAuth} = useAuth();
    let location = useLocation();

    const isMasterPanel = () => {
        return (location.pathname.indexOf("/master/") !== -1);
    }

    const isAdminPanel = () => {
        return (location.pathname.indexOf("/admin/") !== -1);
    }

    const isAuthPage = () => {
        return (location.pathname.indexOf("/auth/") !== -1);
    }

    const isSite = () => {
        return !isAdminPanel() && !isMasterPanel() && !isAuthPage();
    }

    return (
        <UserContext.Consumer>
            {({user, loaded}) => (
                <>
                    <Switch>
                        {isSite() ?
                            <>
                                {loaded ?
                                    <SiteLayout />
                                    :
                                    <FallbackView />
                                }
                            </>
                            :
                            <>
                                {!isAuth() && !isAuthPage() && (
                                    <Redirect to='/auth/login'/>
                                )}

                                {!isAuth() && isAuthPage() && (
                                    /*Render auth page when user at `/auth` and not authorized.*/
                                    <Route>
                                        <AuthPage/>
                                    </Route>
                                )}

                                {isAuth() && (
                                        <>
                                            {loaded ?
                                                (
                                                    <>
                                                        {isMasterPanel() ?
                                                            <MasterLayout />
                                                            :
                                                            <AdminLayout />

                                                        }
                                                        <MasterInit />
                                                    </>
                                                ):
                                                <FallbackView />
                                            }
                                        </>

                                )}

                                <Route path='/error' component={ErrorsPage}/>
                            </>
                        }
                    </Switch>
                </>
            )}
        </UserContext.Consumer>
    )
}

export {Routes}
