import React, {FC} from "react";
import {BusinessBranchModel} from "../../../models/BusinessModel";
import { ReservationModel } from "../../../models/Reservation";
import { Link, useParams } from "react-router-dom";
import { useConfig } from "../../../hooks/useConfig";

interface Props {
    business_branch: BusinessBranchModel
    reservation: ReservationModel
    showInvite?: boolean
    showClose?: boolean
}

const ReservationAdminHeader: FC<Props> = ({business_branch, reservation, showInvite=true, showClose=false}) => {
    const {getConfig} = useConfig();

    return (
        <>
            <div className="container-fluid d-flex app-header align-items-center justify-content-between bg-white position-fixed z-index-3 border border-bottom">
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-circle symbol-45px">
                        <img src={(business_branch.avatar_url !== null) ? business_branch.avatar_url : undefined} alt="user" />
                    </div>
                    <div className="d-block">
                        <span className="fs-7 d-block fw-bolder primary-brand-color text-uppercase ms-3">{business_branch.branch}</span>
                        <span className="fs-7 d-block fw-normal text-black-50 ms-3">{business_branch.name}</span>
                    </div>
                </div>
                {showInvite && (
                    <div className="d-flex">
                        <a href={`https://api.whatsapp.com/send?text=Olá, por favor, confirme sua presença! ${getConfig('SHORT_URL')}/reserva/${business_branch.slug}/${reservation?.id}/${reservation.token_rsvp}/rsvp`} className="btn btn-sm btn-success">Convidar<i className="bi bi-whatsapp ms-3"></i></a>
                    </div>
                )}
                {showClose && (
                    <div className="d-flex">
                        <a href={`${getConfig('SHORT_URL')}/${business_branch.slug}`} className="btn btn-icon">
                            <i className="bi bi-x-lg fs-2hx"></i>
                        </a>
                    </div>
                )}
            </div>
        </>
    );
}

export default ReservationAdminHeader;