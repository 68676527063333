import React, {FC, useState} from "react";
import {BusinessBranchModel} from "../../../models/BusinessModel";
import {useStateList} from "../../../hooks/useStateList";
import { ReservationModel } from "../../../models/Reservation";
import moment from "moment";
import * as Yup from "yup";
import {useFormik} from "formik";
import InputMask from "react-input-mask";
import clsx from "clsx";
import { useAPI } from "../../../hooks/useAPI";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import ReservationAdminHeader from "./ReservationAdminHeader";
import ReservationAdminRSVPFeedback from "./ReservationAdminRSVPFeedback";

interface Props {
    business_branch: BusinessBranchModel
    reservation: ReservationModel
}

type Params = {
    id: string,
    token: string
}

const CommentSchema = Yup.object().shape({
    name: Yup.string()
        .required('Nome é obrigatório.'),
    phone: Yup.string()
        .required('Telefone é obrigatório.'),
    email: Yup.string()
        .email('Email inválido.').required('Email é obrigatório'),
    birth_date: Yup.string()
});

const ReservationAdminRSVP: FC<Props> = ({business_branch, reservation}) => {
    const {getStateShort} = useStateList();
    const {api} = useAPI();
    const [loading, setLoading] = useState(false);
    const {id, token} = useParams<Params>();
    const [success, setSuccess] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: (reservation) ? reservation.name : "",
            email: (reservation) ? reservation.email : "",
            phone: (reservation) ? reservation.phone : "",
            birth_date: (reservation) ? reservation.birth_date : ""
        },
        validationSchema: CommentSchema,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values: any, {setStatus, setSubmitting}) => { 
            setLoading(true);
            api()
            .post(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation/${id}/${token}/person`, values)
            .then((resp) => {
                setLoading(false);
                if(resp.data.success) {
                    setSuccess(true);
                } else {
                    alert('Houve um erro, tente novamente.');
                }
            })
            .catch(() => {
                setLoading(false);
                alert('Houve um erro, tente novamente.')
            });
        },
    });

    return (
        <>
        {loading && (<Loading fullscreen={true} />)}
        <ReservationAdminHeader
                    business_branch={business_branch} 
                    reservation={reservation}
                    showInvite={false}
                    showClose={success}
                     /> 
        {!success && (
            <div className="container app-wrapper mw-500px">
                <div className="card rounded-3 py-5 mt-5">
            <form onSubmit={formik.handleSubmit} noValidate={true}>
                <div className="card-body text-center pb-5">
                    <h1 className="fs-1 primary-brand-color mb-0 fw-normal">Insira os dados abaixo para confirmar a sua presença</h1>
                </div>
                
                <div className="separator separator-dotted border-2 w-75 m-auto"></div>
                <label>
                    <span className="section-ticket dots">
                        <span className="bg-light"></span>
                        <span className="bg-light"></span>
                    </span>
                </label>
                <div className="card-body text-center my-5">
                    <h2 className="fs-1 mb-5" style={{'textTransform': 'capitalize'}}>{reservation?.owner.name}</h2>
                    <h5><span style={{'textTransform': 'capitalize'}}>{moment(reservation?.datetime).format("dddd")}</span>{moment(reservation?.datetime).format(", DD [de] MMMM [de] YYYY")}</h5>
                    <h5 className="mb-5">{moment(reservation?.datetime).format("HH:mm")}h - {reservation?.person_number} Pessoas</h5>
                    <p className="text-gray-700 fs-5 fw-normal mb-10">{`${business_branch.address.street}, ${business_branch.address.number} - ${business_branch.address.district}`} <br /> {`${business_branch.address.city.name} - ${getStateShort(business_branch.address.city.state_id)}, ${business_branch.address.zipcode}`}</p>                
                </div>

                <div className="separator separator-dotted border-2 w-75 m-auto"></div>
                <label>
                    <span className="section-ticket dots">
                        <span className="bg-light"></span>
                        <span className="bg-light"></span>
                    </span>
                </label>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 mb-5">
                            <span className="text-gray-700 fw-bold fs-6 d-block mb-2 required">Nome</span>
                            <input
                                    type="email"
                                    placeholder="Digite seu nome"
                                    maxLength={255}
                                    {...formik.getFieldProps('name')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {'is-invalid': formik.touched.name && formik.errors.name},
                                        {
                                            'is-valid': formik.touched.name && !formik.errors.name,
                                        }
                                    )}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert'>{formik.errors.name}</span>
                                    </div>
                                )}
                        </div>
                        <div className="col-12 mb-5">
                            <span className="text-gray-700 fw-bold fs-6 d-block mb-2 required">Telefone</span>
                            <InputMask
                                    mask={'(99) 99999-9999'}
                                    type="text"
                                    placeholder="Digite seu telefone"
                                    {...formik.getFieldProps('phone')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {'is-invalid': formik.touched.phone && formik.errors.phone},
                                        {
                                            'is-valid': formik.touched.phone && !formik.errors.phone,
                                        }
                                    )}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert'>{formik.errors.phone}</span>
                                    </div>
                                )}
                        </div>
                        <div className="col-12 mb-5">
                            <span className="text-gray-700 fw-bold fs-6 d-block mb-2 required">Email</span>
                            <input
                                    type="email"
                                    placeholder="Digite seu email"
                                    maxLength={255}
                                    {...formik.getFieldProps('email')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {'is-invalid': formik.touched.email && formik.errors.email},
                                        {
                                            'is-valid': formik.touched.email && !formik.errors.email,
                                        }
                                    )}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert'>{formik.errors.email}</span>
                                    </div>
                                )}
                        </div>
                        <div className="col-12 mb-5">
                            <span className="text-gray-700 fw-bold fs-6 d-block mb-2">Data de Nascimento</span>
                            <InputMask
                                    mask={'99/99/9999'}
                                    type="text"
                                    placeholder="Data de nascimento"
                                    {...formik.getFieldProps('birth_date')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {'is-invalid': formik.touched.birth_date && formik.errors.birth_date},
                                        {
                                            'is-valid': formik.touched.birth_date && !formik.errors.birth_date,
                                        }
                                    )}
                                />
                                {formik.touched.birth_date && formik.errors.birth_date && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert'>{formik.errors.birth_date}</span>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div className="separator separator-dotted border-2 w-75 m-auto"></div>
                <label>
                    <span className="section-ticket dots">
                        <span className="bg-light"></span>
                        <span className="bg-light"></span>
                    </span>
                </label>
                <div className="card-body">
                    <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-lg btn-success shadow py-5 w-70">Confirmar</button>
                    </div>
                </div>
                </form>
                </div></div>
        )}

        {success && (<ReservationAdminRSVPFeedback business_branch={business_branch} reservation={reservation}/>)}
        </>
    );
}

export default ReservationAdminRSVP;