import React, {FC, useContext, useEffect, useState} from "react";
import {PageProps} from "../../models/Page";
import {useAPI} from "../../hooks/useAPI";
import Loading from "../../components/Loading";
import ErrorLoading from "../../components/ErrorLoading";
import {useConfig} from "../../hooks/useConfig";
import clsx from "clsx";
import {ResearchModel} from "../../models/Research";
import ResearchCommentRow from "./ResearchCommentRow";
import CommentModal from "./CommentModal";

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import ResearchOverviewNPSChart from "./ResearchOverviewNPSChart";
import ResearchOverviewBadge from "./ResearchOverviewBadge";
import {Tooltip} from "bootstrap";
import ResearchOverviewGenderCard from "./ResearchOverviewGenderCard";
import ResearchOverviewTriesChart from "./ResearchOverviewTriesChart";
import moment from "moment";
import 'moment/locale/pt-br';

interface OverviewResponse {
    final_note: number
    food_note: number
    nps: number
    place_note: number
    price_note: number
    return_avg: number
    time_note: number
    total_negative: number
    total_neutral: number
    total_nps: number
    total_positive: number
    total_note: number
    treatment_note: number
    percent_negative: number
    percent_neutral: number
    percent_positive: number
    nps_history: Array<any>
    age_stats: Array<any>
    tries_history: Array<any>
    error?: boolean
    code?: number
    msg?: string
}

const ResearchOverviewPage: FC<PageProps> = ({updateToolbar, business_branch}) => {
    const [showAddMenuModal, setShowAddMenuModal] = useState(false);
    const {getConfig} = useConfig();

    const formatDate = (date: string) => {
        moment.locale('pt-br');
        return moment(date).format("DD MMM");
    }

    useEffect(() => {
        updateToolbar({
            title: "Avaliações",
            breadcrumbs: [
                {
                    title: "Pesquisa",
                    path: '/admin/research/comment',
                    isSeparator: false,
                    isActive: false,
                },
                {
                    title: '',
                    path: '',
                    isSeparator: true,
                    isActive: false,
                },
            ],
            buttons: []
        });
        loadOverview();
    }, []);

    const [loading, setLoading] = useState(true);
    const [errorLoading, setErrorLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [overview, setOverview] = useState<OverviewResponse | undefined>(undefined);
    const {api} = useAPI();

    const loadOverview = () => {
        setLoading(true);
        setHasError(false);
        setErrorLoading(false);
        api().get(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/research/overview`).then((resp) => {
            setLoading(false);
            if(resp.data.error !== true){
                setOverview(resp.data);
            } else {
                setHasError(true);
            }

        }).catch(() => {
            setLoading(false);
            setErrorLoading(true);
        })
    }

    useEffect(() => {
        Array.from(document.querySelectorAll(`.tooltip`))
            .forEach(node => node.remove());
        Array.from(document.querySelectorAll(`[data-bs-toggle="tooltip"]`))
            .forEach(node => new Tooltip(node));
    }, [overview]);

    return (
      <>
          {loading && (
              <Loading />
          )}
          {hasError && (<h3>Dados insuficientes, tente novamente mais tarde.</h3>)}
          {errorLoading && (<ErrorLoading onTryAgain={loadOverview} />)}
          {!errorLoading && overview !== undefined && !hasError && (
              <div className="row gy-5 g-xl-10">
                  {/*begin::Col*/}
                  <div className="col-xxl-7">
                      {/*begin::Row*/}
                      <div className="row gx-5 gx-xl-10">
                          {/*begin::Col*/}
                          <div className="col-sm-7 col-md-7 col-lg-6 mb-5 mb-xl-10">
                              {/*begin::List widget 1*/}
                              <div className="card card-flush h-lg-100">
                                  {/*begin::Header*/}
                                  <div className="card-header pt-5">
                                      {/*begin::Title*/}
                                      <div className="card-title d-flex flex-column">
                                          {/*begin::Info*/}
                                          <div className="d-flex align-items-center">
                                              {/*begin::Amount*/}
                                              <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">{overview?.nps} NPS</span>
                                              <ResearchOverviewBadge past={overview.nps_history[0].nps} actual={overview.nps_history[overview.nps_history.length - 1].nps} />
                                          </div>
                                          {/*end::Info*/}
                                          {/*begin::Subtitle*/}
                                          <span className="text-gray-400 pt-1 fw-bold fs-6">{overview?.total_nps} avaliações</span>
                                          {/*end::Subtitle*/}
                                      </div>
                                      {/*end::Title*/}
                                  </div>
                                  {/*end::Header*/}
                                  {/*begin::Body*/}
                                  <div className="card-body pt-5">
                                      {/*begin::Item*/}
                                      <div className="d-flex flex-stack">
                                          {/*begin::Section*/}
                                          <div className="text-gray-700 fw-bold fs-6 me-2">
                                              Promotores
                                              <i className="fas fa-exclamation-circle ms-1 fs-7"
                                                 data-bs-toggle="tooltip" title=""
                                                 data-bs-original-title="Clientes que gostam e indicariam a sua empresa"
                                                 aria-label="Clientes que gostam e indicariam a sua empresa"></i>
                                          </div>
                                          {/*end::Section*/}
                                          {/*begin::Statistics*/}
                                          <div className="d-flex align-items-center">
                                              {/*begin::Bullet*/}
                                              <span className="bullet bullet-dot bg-success me-2 h-10px w-10px"></span>
                                              {/*end::Bullet*/}
                                              {/*begin::Number*/}
                                              <span className="text-gray-900 fw-boldest fs-6">{overview?.percent_positive}%</span>
                                              {/*end::Number*/}
                                          </div>
                                          {/*end::Statistics*/}
                                      </div>
                                      {/*end::Item*/}
                                      {/*begin::Separator*/}
                                      <div className="separator separator-dashed my-3"></div>
                                      {/*end::Separator*/}
                                      {/*begin::Item*/}
                                      <div className="d-flex flex-stack">
                                          {/*begin::Section*/}
                                          <div className="text-gray-700 fw-bold fs-6 me-2">
                                              Neutros
                                              <i className="fas fa-exclamation-circle ms-1 fs-7"
                                                 data-bs-toggle="tooltip" title=""
                                                 data-bs-original-title="Clientes que não estão insatisfeitos, mas não indicariam."
                                                 aria-label="Clientes que não estão insatisfeitos, mas não indicariam."></i>
                                          </div>
                                          {/*end::Section*/}
                                          {/*begin::Statistics*/}
                                          <div className="d-flex align-items-center">
                                              {/*begin::Bullet*/}
                                              <span className="bullet bullet-dot bg-warning me-2 h-10px w-10px"></span>
                                              {/*end::Bullet*/}
                                              {/*begin::Number*/}
                                              <span className="text-gray-900 fw-boldest fs-6">{overview?.percent_neutral}%</span>
                                              {/*end::Number*/}
                                          </div>
                                          {/*end::Statistics*/}
                                      </div>
                                      {/*end::Item*/}
                                      {/*begin::Separator*/}
                                      <div className="separator separator-dashed my-3"></div>
                                      {/*end::Separator*/}
                                      {/*begin::Item*/}

                                      <div className="d-flex flex-stack">
                                          {/*begin::Section*/}
                                          <div className="text-gray-700 fw-bold fs-6 me-2">
                                              Detratores
                                              <i className="fas fa-exclamation-circle ms-1 fs-7"
                                                 data-bs-toggle="tooltip" title=""
                                                 data-bs-original-title="Clientes infatisfeitos com a sua empresa e não indicariam."
                                                 aria-label="Clientes infatisfeitos com a sua empresa e não indicariam."></i>
                                          </div>
                                          {/*end::Section*/}
                                          {/*begin::Statistics*/}
                                          <div className="d-flex align-items-center">
                                              {/*begin::Bullet*/}
                                              <span className="bullet bullet-dot bg-danger me-2 h-10px w-10px"></span>
                                              {/*end::Bullet*/}
                                              {/*begin::Number*/}
                                              <span className="text-gray-900 fw-boldest fs-6">{overview?.percent_negative}%</span>
                                              {/*end::Number*/}
                                          </div>
                                          {/*end::Statistics*/}
                                      </div>
                                      {/*end::Item*/}
                                  </div>
                                  {/*end::Body*/}
                              </div>
                              {/*end::LIst widget 1*/}
                          </div>
                          {/*end::Col*/}
                          {/*begin::Col*/}
                          <div className="col-sm-5 col-md-5 col-lg-6 mb-5 mb-xl-10">
                              <ResearchOverviewGenderCard age_stats={overview.age_stats} />
                          </div>
                          {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                      {/*begin::Tables widget 1*/}
                      <div className="card card-flush mb-xxl-10">
                          {/*begin::Header*/}
                          <div className="card-header pt-5">
                              {/*begin::Title*/}
                              <h3 className="card-title align-items-start flex-column">
                                  <span className="card-label fw-bolder text-dark">Net Promoter Score</span>
                                  <span className="text-gray-400 pt-2 fw-bold fs-6">Evolução dos últimos 30 dias</span>
                              </h3>
                              {/*end::Title*/}
                          </div>
                          {/*end::Header*/}
                          {/*begin::Body*/}
                          <div className="card-body">
                              {/*begin::Chart*/}
                              <ResearchOverviewNPSChart series={overview.nps_history} />
                              {/*end::Chart*/}
                          </div>
                          {/*end: Card Body*/}
                      </div>
                      {/*end::Tables widget 1*/}
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="col-xxl-5 mb-5 mb-xl-10">
                      <div className="row gx-5 gx-xl-10">
                          <div className="col-sm-7 col-md-7 col-lg-6 col-xl-12 mb-5 mb-xl-10">
                              {/*begin::List widget 2*/}
                              <div className="card card-flush">
                                  {/*begin::Header*/}
                                  <div className="card-header pt-3">
                                      {/*begin::Title*/}
                                      <h2 className="card-title align-items-start flex-column">
                                          <span className="card-label fw-bolder text-dark">Avaliações</span>
                                      </h2>
                                      {/*end::Title*/}
                                      <div className="card-toolbar">
                                          <ResearchOverviewBadge past={overview.nps_history[0].final_note} actual={overview.nps_history[overview.nps_history.length - 1].final_note} />
                                      </div>
                                  </div>
                                  {/*end::Header*/}
                                  {/*begin::Body*/}
                                  <div className="card-body pt-5 align-items-center text-center">
                                      <span className="fs-3hx fw-bolder text-dark me-2 lh-1 ls-n2">{overview?.final_note}</span>
                                      <div className="d-flex align-items-center text-center w-100 mb-4">
                                          <div className="rating m-auto">
                                              <div className={clsx("rating-label", {
                                                  "checked": overview?.final_note >= 1
                                              })}>
                                                <span className="svg-icon svg-icon-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         width="24" height="24" viewBox="0 0 24 24"
                                                         fill="none">
                                                        <path
                                                            d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                                                            fill="black"></path>
                                                    </svg>
                                                </span>
                                              </div>
                                              <div className={clsx("rating-label", {
                                                  "checked": overview?.final_note >= 2
                                              })}>
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="24" height="24" viewBox="0 0 24 24"
                                                                             fill="none">
																			<path
                                                                                d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                                                                                fill="black"></path>
																		</svg>
																	</span>
                                              </div>
                                              <div className={clsx("rating-label", {
                                                  "checked": overview?.final_note >= 3
                                              })}>
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="24" height="24" viewBox="0 0 24 24"
                                                                             fill="none">
																			<path
                                                                                d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                                                                                fill="black"></path>
																		</svg>
																	</span>
                                              </div>
                                              <div className={clsx("rating-label", {
                                                  "checked": overview?.final_note >= 4
                                              })}>
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="24" height="24" viewBox="0 0 24 24"
                                                                             fill="none">
																			<path
                                                                                d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                                                                                fill="black"></path>
																		</svg>
																	</span>
                                              </div>
                                              <div className={clsx("rating-label", {
                                                  "checked": overview?.final_note >= 5
                                              })}>
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="24" height="24" viewBox="0 0 24 24"
                                                                             fill="none">
																			<path
                                                                                d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                                                                                fill="black"></path>
																		</svg>
																	</span>
                                              </div>
                                          </div>
                                      </div>
                                      <span className="text-gray-400 mt-1 fw-bold fs-6">{overview.total_note} avaliações</span>
                                  </div>
                                  <div className="card-body pt-0">
                                      {/*begin::Item*/}
                                      <div className="d-flex align-items-center bg-light-warning rounded p-5 mb-3">
                                          {/*begin::Icon*/}
                                          <span className="svg-icon svg-icon-warning me-5">
																{/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
                                              <span className="svg-icon svg-icon-1">
																	<svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                         height="16" fill="currentColor"
                                                                         className="bi bi-egg-fried"
                                                                         viewBox="0 0 16 16">
																	<path d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
																	<path
                                                                        d="M13.997 5.17a5 5 0 0 0-8.101-4.09A5 5 0 0 0 1.28 9.342a5 5 0 0 0 8.336 5.109 3.5 3.5 0 0 0 5.201-4.065 3.001 3.001 0 0 0-.822-5.216zm-1-.034a1 1 0 0 0 .668.977 2.001 2.001 0 0 1 .547 3.478 1 1 0 0 0-.341 1.113 2.5 2.5 0 0 1-3.715 2.905 1 1 0 0 0-1.262.152 4 4 0 0 1-6.67-4.087 1 1 0 0 0-.2-1 4 4 0 0 1 3.693-6.61 1 1 0 0 0 .8-.2 4 4 0 0 1 6.48 3.273z"></path>
																	</svg>
																</span>
                                              {/*end::Svg Icon*/}
															</span>
                                          {/*end::Icon*/}
                                          {/*begin::Title*/}
                                          <div className="flex-grow-1 me-2">
                                              <h3 className="fw-bolder text-gray-800 fs-6 m-0">Comida</h3>
                                          </div>
                                          {/*end::Title*/}
                                          {/*begin::Lable*/}
                                          <span className="text-gray-900 fw-boldest fs-6">{overview.food_note}</span>
                                          {/*end::Lable*/}
                                      </div>
                                      {/*end::Item*/}
                                      {/*begin::Item*/}
                                      <div className="d-flex align-items-center bg-light-success rounded p-5 mb-3">
                                          {/*begin::Icon*/}
                                          <span className="svg-icon svg-icon-success me-5">
																{/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
                                              <span className="svg-icon svg-icon-1">
																	<svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                         height="16" fill="currentColor"
                                                                         className="bi bi-person-badge"
                                                                         viewBox="0 0 16 16">
																	<path
                                                                        d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path>
																	<path
                                                                        d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z"></path>
																	</svg>
																</span>
                                              {/*end::Svg Icon*/}
															</span>
                                          {/*end::Icon*/}
                                          {/*begin::Title*/}
                                          <div className="flex-grow-1 me-2">
                                              <h3 className="fw-bolder text-gray-800 fs-6 m-0">Atendimento</h3>
                                          </div>
                                          {/*end::Title*/}
                                          {/*begin::Lable*/}
                                          <span className="text-gray-900 fw-boldest fs-6">{overview.treatment_note}</span>
                                          {/*end::Lable*/}
                                      </div>
                                      {/*end::Item*/}
                                      {/*begin::Item*/}
                                      <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-3">
                                          {/*begin::Icon*/}
                                          <span className="svg-icon svg-icon-danger me-5">
																{/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
                                              <span className="svg-icon svg-icon-1">
																	<svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                         height="16" fill="currentColor"
                                                                         className="bi bi-house-heart"
                                                                         viewBox="0 0 16 16">
																	<path fill-rule="evenodd"
                                                                          d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.707L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.646a.5.5 0 0 0 .708-.707L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207l-5-5-5 5V13.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7.207Zm-5-.225C9.664 5.309 13.825 8.236 8 12 2.175 8.236 6.336 5.309 8 6.982Z"></path>
																	</svg>
																</span>
                                              {/*end::Svg Icon*/}
															</span>
                                          {/*end::Icon*/}
                                          {/*begin::Title*/}
                                          <div className="flex-grow-1 me-2">
                                              <h3 className="fw-bolder text-gray-800 fs-6 m-0">Ambiente</h3>
                                          </div>
                                          {/*end::Title*/}
                                          {/*begin::Lable*/}
                                          <span className="text-gray-900 fw-boldest fs-6">{overview.place_note}</span>
                                          {/*end::Lable*/}
                                      </div>
                                      {/*end::Item*/}
                                      {/*begin::Item*/}
                                      <div className="d-flex align-items-center bg-light-info rounded p-5 mb-3">
                                          {/*begin::Icon*/}
                                          <span className="svg-icon svg-icon-info me-5">
																{/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
                                              <span className="svg-icon svg-icon-1">
																	<svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                         height="16" fill="currentColor"
                                                                         className="bi bi-piggy-bank"
                                                                         viewBox="0 0 16 16">
																	<path
                                                                        d="M5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm1.138-1.496A6.613 6.613 0 0 1 7.964 4.5c.666 0 1.303.097 1.893.273a.5.5 0 0 0 .286-.958A7.602 7.602 0 0 0 7.964 3.5c-.734 0-1.441.103-2.102.292a.5.5 0 1 0 .276.962z"></path>
																	<path fill-rule="evenodd"
                                                                          d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595zM2.516 6.26c.455-2.066 2.667-3.733 5.448-3.733 3.146 0 5.536 2.114 5.536 4.542 0 1.254-.624 2.41-1.67 3.248a.5.5 0 0 0-.165.535l.66 2.175h-.985l-.59-1.487a.5.5 0 0 0-.629-.288c-.661.23-1.39.359-2.157.359a6.558 6.558 0 0 1-2.157-.359.5.5 0 0 0-.635.304l-.525 1.471h-.979l.633-2.15a.5.5 0 0 0-.17-.534 4.649 4.649 0 0 1-1.284-1.541.5.5 0 0 0-.446-.275h-.56a.5.5 0 0 1-.492-.414l-.254-1.46h.933a.5.5 0 0 0 .488-.393zm12.621-.857a.565.565 0 0 1-.098.21.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199z"></path>
																	</svg>
																</span>
                                              {/*end::Svg Icon*/}
															</span>
                                          {/*end::Icon*/}
                                          {/*begin::Title*/}
                                          <div className="flex-grow-1 me-2">
                                              <h3 className="fw-bolder text-gray-800 fs-6 m-0">Preço</h3>
                                          </div>
                                          {/*end::Title*/}
                                          {/*begin::Lable*/}
                                          <span className="text-gray-900 fw-boldest fs-6">{overview.price_note}</span>
                                          {/*end::Lable*/}
                                      </div>
                                      {/*end::Item*/}
                                      {/*begin::Item*/}
                                      <div className="d-flex align-items-center bg-light-primary rounded p-5">
                                          {/*begin::Icon*/}
                                          <span className="svg-icon svg-icon-info me-5">
																{/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
                                              <span className="svg-icon svg-icon-1">
																	<svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                         height="16" fill="currentColor"
                                                                         className="bi bi-hourglass-split"
                                                                         viewBox="0 0 16 16">
																	<path
                                                                        d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"></path>
																	</svg>
																</span>
                                              {/*end::Svg Icon*/}
															</span>
                                          {/*end::Icon*/}
                                          {/*begin::Title*/}
                                          <div className="flex-grow-1 me-2">
                                              <h3 className="fw-bolder text-gray-800 fs-6 m-0">Tempo</h3>
                                          </div>
                                          {/*end::Title*/}
                                          {/*begin::Lable*/}
                                          <span className="text-gray-900 fw-boldest fs-6">{overview.time_note}</span>
                                          {/*end::Lable*/}
                                      </div>
                                      {/*end::Item*/}
                                  </div>
                                  {/*end::Body*/}
                              </div>
                              {/*end::List widget 2*/}
                          </div>
                          <div className="col-sm-7 col-md-7 col-lg-6 col-xl-12 mb-5 mb-xl-10">
                              {/*begin::Tentativas*/}
                              {/*begin::Mixed Widget 10*/}
                              <div className="card card-xl-stretch-50">
                                  {/*begin::Body*/}
                                  <div
                                      className="card-body p-0 d-flex justify-content-between flex-column overflow-hidden">
                                      {/*begin::Hidden*/}
                                      <div className="d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3">
                                          <div className="me-2">
                                              <span className="fw-bolder text-gray-800 d-block fs-3">Tentativas</span>
                                              <span className="text-gray-400 fw-bold">{formatDate(overview.tries_history[0].date)} - {formatDate(overview.tries_history[overview.tries_history.length-1].date)}</span>
                                          </div>
                                          <div className="fw-bolder fs-3 text-primary">{overview.tries_history.length} dias</div>
                                      </div>
                                      {/*end::Hidden*/}
                                      {/*begin::Chart*/}
                                      <ResearchOverviewTriesChart series={overview.tries_history} />
                                      {/*end::Chart*/}
                                  </div>
                              </div>
                              {/*end::Mixed Widget 10*/}
                              {/*begin::Tentativas*/}
                          </div>
                          <div className="col-sm-7 col-md-7 col-lg-6 col-xl-12 mb-5 mb-xl-10">
                              {/*begin::Tentativas*/}
                              {/*begin::Mixed Widget 10*/}
                              <div className="card card-xl-stretch-50">
                                  {/*begin::Body*/}
                                  <div
                                      className="card-body p-0 d-flex justify-content-between flex-column overflow-hidden">
                                      {/*begin::Hidden*/}
                                      <div className="d-flex flex-stack flex-wrap flex-grow-1 px-9 py-9">
                                          <div className="me-2">
                                              <span
                                                  className="fw-bolder text-gray-800 d-block fs-3">Taxa de Retorno</span>
                                              <span className="text-gray-400 fw-bold">Pessoas que retornaram ao seu estabelecimento</span>
                                          </div>
                                          <div className="fw-bolder fs-1 text-primary">{overview.return_avg}%</div>
                                      </div>
                                      {/*end::Hidden*/}
                                  </div>
                              </div>
                              {/*end::Mixed Widget 10*/}
                              {/*begin::Tentativas*/}
                          </div>
                      </div>
                  </div>
                  {/*end::Col*/}
              </div>
          )}
              
      </>
    );
}

export default ResearchOverviewPage;