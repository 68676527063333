import React, {FC, useEffect} from "react";

interface Props {
    age_stats: any
}
const ResearchOverviewGenderCard: FC<Props> = ({age_stats}) => {
    let startFill = 0;
    useEffect(() => {
        var el = document.getElementById('kt_card_widget_4_chart');

        if (!el) {
            return;
        }

        var options = {
            size: 70,
            lineWidth: 11,
            rotate: 145,
            //percent:  el.getAttribute('data-kt-percent') ,
        }

        var canvas = document.createElement('canvas');
        var span = document.createElement('span');

        var ctx = canvas.getContext('2d');
        canvas.width = canvas.height = options.size;

        el.appendChild(span);
        el.appendChild(canvas);

        if (ctx !== null) {
            ctx.translate(options.size / 2, options.size / 2); // change center
            ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI); // rotate -90 deg
        }


        //imd = ctx.getImageData(0, 0, 240, 240);
        var radius = (options.size - options.lineWidth) / 2;

        var drawCircle = function(color: any, lineWidth: any, percent: any) {
            if(percent <= 0) {return}
            percent = Math.min(Math.max(0, percent || 1), 1);
            if(ctx !== null) {
                ctx.beginPath();
                ctx.arc(0, 0, radius, startFill, (Math.PI * 2 * percent) + startFill, false);
                ctx.strokeStyle = color;
                ctx.lineCap = 'butt'; // butt, round or square
                ctx.lineWidth = lineWidth
                ctx.stroke();
                startFill += Math.PI * 2 * percent;
            }
        };

        // Init
        drawCircle('#E4E6EF', options.lineWidth, 100 / 100);
        drawCircle('#fab041', options.lineWidth, age_stats.age1824 / age_stats.total);
        drawCircle('#78c722', options.lineWidth, age_stats.age2534 / age_stats.total);
        drawCircle('#2274c7', options.lineWidth, age_stats.age3544 / age_stats.total);
        drawCircle('#fb6400', options.lineWidth, age_stats.age4554 / age_stats.total);
        drawCircle('#a733d7', options.lineWidth, age_stats.age5564 / age_stats.total);
        drawCircle('#6c6458', options.lineWidth, age_stats.age65 / age_stats.total);
    }, []);

    return (
        <>
            <div className="card card-flush h-lg-100">
                {/*begin::Header*/}
                <div className="card-header pt-0">
                    <h2 className="card-title align-items-start flex-column m-0">
                        <span className="card-label fw-bolder text-dark">Público</span>
                    </h2>
                    <div className="card-toolbar">
                        <span className="svg-icon svg-icon-warning me-5">
                            {/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
                            <span className="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                     height="16" fill="currentColor"
                                     className="bi bi-people-fill"
                                     viewBox="0 0 16 16">
                                    <path
                                        d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    <path fill-rule="evenodd"
                                          d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                                    <path
                                        d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                                  </svg>
                            </span>
                            {/*end::Svg Icon*/}
                        </span>
                    </div>
                </div>
                {/*end::Header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0 pb-5 d-flex align-items-center">
                    {/*begin::Chart - Masculino vs Feminino*/}
                    <div className="d-flex flex-center me-5 pt-0">
                        <div id="kt_card_widget_4_chart" style={{"minWidth": "110px", "minHeight": "110px"}}
                             data-kt-size="70" data-kt-line="11" />
                    </div>
                    {/*end::Chart*/}
                    {/*begin::Labels*/}
                    <div className="d-flex flex-column content-justify-center w-100">
                        {/*begin::Label*/}
                        <div className="d-flex fs-6 fw-bold align-items-center">
                            <div className="bullet w-8px h-6px rounded-2 me-3" style={{"backgroundColor": "#fab041"}}/>
                            {/*begin::Label*/}
                            <div className="text-gray-500 flex-grow-1 me-4">18-24</div>
                            {/*end::Label*/}
                            {/*begin::Stats*/}
                            <div className="fw-boldest text-gray-700 text-xxl-end">{(age_stats.age1824 / age_stats.total * 100).toFixed(2)}%</div>
                            {/*end::Stats*/}
                        </div>
                        {/*end::Label*/}
                        {/*begin::Label*/}
                        <div className="d-flex fs-6 fw-bold align-items-center">
                            {/*begin::Label*/}
                            <div className="bullet w-8px h-6px rounded-2 me-3" style={{"backgroundColor": "#78c722"}}/>
                            {/*begin::Label*/}
                            <div className="text-gray-500 flex-grow-1 me-4">25-34</div>
                            {/*end::Label*/}
                            {/*begin::Stats*/}
                            <div className="fw-boldest text-gray-700 text-xxl-end">{(age_stats.age2534 / age_stats.total * 100).toFixed(2)}%</div>
                            {/*end::Stats*/}
                        </div>
                        {/*end::Label*/}
                        {/*begin::Label*/}
                        <div className="d-flex fs-6 fw-bold align-items-center">
                            <div className="bullet w-8px h-6px rounded-2 me-3" style={{"backgroundColor": "#2274c7"}}/>
                            {/*begin::Label*/}
                            <div className="text-gray-500 flex-grow-1 me-4">35-44</div>
                            {/*end::Label*/}
                            {/*begin::Stats*/}
                            <div className="fw-boldest text-gray-700 text-xxl-end">{(age_stats.age3544 / age_stats.total * 100).toFixed(2)}%</div>
                        </div>
                        {/*end::Label*/}
                        {/*begin::Label*/}
                        <div className="d-flex fs-6 fw-bold align-items-center">
                            {/*begin::Label*/}
                            <div className="bullet w-8px h-6px rounded-2 me-3" style={{"backgroundColor": "#fb6400"}}/>
                            {/*begin::Label*/}
                            <div className="text-gray-500 flex-grow-1 me-4">45-54</div>
                            {/*end::Label*/}
                            {/*begin::Stats*/}
                            <div className="fw-boldest text-gray-700 text-xxl-end">{(age_stats.age4554 / age_stats.total * 100).toFixed(2)}%</div>
                            {/*end::Stats*/}
                        </div>
                        {/*end::Label*/}
                        {/*begin::Label*/}
                        <div className="d-flex fs-6 fw-bold align-items-center">
                            {/*begin::Label*/}
                            <div className="bullet w-8px h-6px rounded-2 me-3" style={{"backgroundColor": "#a733d7"}}/>
                            {/*begin::Label*/}
                            <div className="text-gray-500 flex-grow-1 me-4">55-64</div>
                            {/*end::Label*/}
                            {/*begin::Stats*/}
                            <div className="fw-boldest text-gray-700 text-xxl-end">{(age_stats.age5564 / age_stats.total * 100).toFixed(2)}%</div>
                            {/*end::Stats*/}
                        </div>
                        {/*end::Label*/}
                        {/*begin::Label*/}
                        <div className="d-flex fs-6 fw-bold align-items-center">
                            {/*begin::Label*/}
                            <div className="bullet w-8px h-6px rounded-2 me-3" style={{"backgroundColor": "#6c6458"}}/>
                            {/*begin::Label*/}
                            <div className="text-gray-500 flex-grow-1 me-4">65+</div>
                            {/*end::Label*/}
                            {/*begin::Stats*/}
                            <div className="fw-boldest text-gray-700 text-xxl-end">{(age_stats.age65 / age_stats.total * 100).toFixed(2)}%</div>
                            {/*end::Stats*/}
                        </div>
                        {/*end::Label*/}
                    </div>
                    {/*end::Labels*/}
                </div>
                {/*end::Card body*/}
            </div>
        </>
    );
}

export default ResearchOverviewGenderCard;