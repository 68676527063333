import React, {FC, createContext, useReducer, useMemo, useRef} from "react";

function SaveChangesReducer(state: any, action: any) {
    let item;
    switch (action.type) {
        case "setEditedItem":
            for(let i in state.editedItems) {
                item = state.editedItems[i];
                if(item.id === action.id) {
                    state.editedItems[i] = action.item;
                    return {...state, editedItems: state.editedItems};
                }
            }
            state.editedItems.push(action.item);
            return {...state, editedItems: state.editedItems};
        case "setOriginalItem":
            for(let i in state.originalItems) {
                item = state.originalItems[i];
                if(item.id === action.id) {
                    return {...state};
                }
            }
            state.originalItems.push(action.item);
            return {...state, originalItems: state.originalItems};
        default:
            return state;
    }
}


const DefaulSaveChangesContext = {
    editedItems: [],
    originalItems: [],
    setEditedItem: (id: number, item: any) => {},
    setOriginalItem: (id: number, item: any) => {},
    save: (saveFunc: any) => {}
};
const SaveChangesContext = createContext(DefaulSaveChangesContext);
const SaveChangesProvider: FC = ({children}) => {
    const setEditedItem = (id: number, item: any) => {
        data.current = SaveChangesReducer(data.current, {type: 'setEditedItem', id: id, item: item});
        //dispatch({type: 'setEditedItem', id: id, item: item});
    }
    const setOriginalItem = (id: number, item: any) => {
        //dispatch({type: 'setOriginalItem', id: id, item: item});
    }

    const save = (saveFunc: any) => {
        saveFunc(data.current.editedItems);
    }

    const data = useRef({
        ...DefaulSaveChangesContext,
        setEditedItem: setEditedItem,
        setOriginalItem: setOriginalItem,
        save: save
    });

    return (
        <SaveChangesContext.Provider value={data.current}>{children}</SaveChangesContext.Provider>
    );
}

export {SaveChangesContext, SaveChangesProvider};