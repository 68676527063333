export function useBreadCrumb() {
    const setBreadCrumbObject = (object: any) => {
        window.localStorage.setItem("breadcrumb_object", JSON.stringify(object));
    }

    const getBreadCrumbObject = () => {
        if(window.localStorage.getItem("breadcrumb_object") === undefined) {return undefined};
        // @ts-ignore
        return JSON.parse(window.localStorage.getItem("breadcrumb_object"));
    }

    return {
        setBreadCrumbObject,
        getBreadCrumbObject
    };
}
