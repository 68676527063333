import { useAPI } from "./useAPI";
import download from "downloadjs";

export function useHelper() {

  const { api } = useAPI();

  const isMobile = () => {
    return (window.innerWidth < 992) ? true : false;
  }

  const onlyNumbers = (string: string) => {
    return string.replace(/\D/g, "");
  }

  const formatPhone = (v: string) => {
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }

  const downloadFile = (url: string | null | undefined, filename: string = "arquivo") => {

    return new Promise((resolve) => {
      if (url) {
        api().download(url).then(response => {
          console.log(response)
          const content = response.headers['content-type'];          
          const name = filename;
          download(response.data, name, content);
          resolve(true);
        })
          .catch(error => {
            resolve(false);
          });
      }
    });
  }

  return {
    isMobile,
    onlyNumbers,
    formatPhone,
    downloadFile
  };
}