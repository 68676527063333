import {BusinessBranchModel, BusinessModel} from "../models/BusinessModel";
import {ScheduleMetaModel, ScheduleModel} from "../models/Schedule";
import {UserModel} from "../models/UserModel";
import {LinkModel} from "../models/Links";
import {AddressModel, CityModel} from "../models/Address";

const DefaultSchedule: ScheduleModel = {
    id: -1,
    type: "",
    meta: []
}

const DefaultCity: CityModel = {
    id: -1,
    name: "",
    state_id: -1
}

const DefaultAddress: AddressModel = {
    id: -1,
    zipcode: "",
    street: "",
    number: "",
    district: "",
    complement: "",
    city_id: -1,
    state_id: -1,
    city: DefaultCity
}

const DefaultBusinessBranch: BusinessBranchModel = {
    id: -1,
    created_at: "",
    updated_at: "",
    name: "",
    status: "",
    business_id: -1,
    plan_id: -1,
    is_draft: true,
    avatar_path: "",
    avatar_url: "",
    header_image_path: "",
    header_image_url: "",
    description: "",
    branch: "",
    business_phone: "",
    phone: "",
    phone_is_whatsapp: true,
    email: "",
    address_id: -1,
    slug: "",
    address: DefaultAddress,
    schedule: DefaultSchedule,
    users: [],
    links: [],
    categories: "",
    color_primary: null,
    color_secondary: null,
    facebook_pixel: null,
    google_analytics: null,
    highlights: [],
    galleries: [],
    info: "",
    payment_methods: ""
}

const DefaultBusiness: BusinessModel = {
    avatar_path: "",
    avatar_url: "",
    branches: [],
    business_owner_id: -1,
    created_at: "",
    entity_address_id: -1,
    entity_doc: "",
    entity_name: "",
    entity_type: "",
    id: -1,
    name: "",
    status: "",
    type: "",
    updated_at: ""
}

export {DefaultBusinessBranch, DefaultBusiness};