/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'

const FooterSite: FC = () => {
  return (
    <footer className="container p-5 text-center text-muted">
        <div className="pb-5">
            <a href="http://nexpdigital.com.br/" className="pb-5" target="_blank">
                <span className="text-gray-400 mt-1 fw-semibold fs-7 me-2">Criado com</span>
                <img className="mw-50px svg-icon-success" src="/media/logos/logo-1.svg" alt="user" />
            </a>
        </div>
    </footer>
  )
}

export {FooterSite}
