import { useAPI } from "./useAPI";
import { BusinessResponseModel, ResponseModel } from "../models";
import { CustomerModel } from "../models/CustomerModel";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export function useCustomer() {
    const { api } = useAPI();

    const find = async (phone: string, token: string): Promise<CustomerModel | undefined> => {
        return new Promise<CustomerModel | undefined>(async (resolve) => {            
            api().get(`/api/customer/${phone}/find?recaptcha-token=${token}`).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                resolve(undefined);
            });
        });
    }

    const findOnBranch = async (business_id: number, business_branch_id: number, phone: string): Promise<CustomerModel | undefined> => {
        return new Promise<CustomerModel | undefined>(async (resolve) => {            
            api().get(`/api/business/${business_id}/branch/${business_branch_id}/customer/${phone}/find`).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                resolve(undefined);
            });
        });
    }

    return {
        find,
        findOnBranch
    };
}
