import React, {FC} from "react";
import {BusinessBranchModel} from "../../../models/BusinessModel";
import { ReservationModel } from "../../../models/Reservation";
import { Link, useParams } from "react-router-dom";
import { useConfig } from "../../../hooks/useConfig";
import ReservationAdminHeader from "./ReservationAdminHeader";

interface Props {
    business_branch: BusinessBranchModel
    reservation: ReservationModel
}
type Params = {
    id: string,
    token: string
}

const ReservationAdminGuests: FC<Props> = ({business_branch, reservation}) => {
    const {id, token} = useParams<Params>();
    const {getConfig} = useConfig();
    const getInitals = (name: string) => {
        const split = name.split(' ');
        return (split.length > 1) ? `${split[0][0]}${split[1][0]}` : `${split[0][0]}${split[0][1]}`;
    }

    return (
        <>
        <ReservationAdminHeader
                    business_branch={business_branch} 
                    reservation={reservation} /> 
        <div className="container app-wrapper mw-500px">
                        
            <div className="card rounded-3 py-5 mt-5">
            <div className="card-body text-center pb-5">
                <h1 className="fs-1 primary-brand-color mb-0 fw-normal">Convidados que confirmaram presença até agora</h1>
            </div>
            <div className="separator separator-dotted border-2 w-75 m-auto"></div>
            <label>
                <span className="section-ticket dots">
                    <span className="bg-light"></span>
                    <span className="bg-light"></span>
                </span>
            </label>
            
            {reservation.guests.length === 0 && (
                <div className="card-body text-center">
                    <h2 className="fs-2 text-muted mb-0 fw-normal">Nenhum convidado confirmado até o momento.</h2>
                </div>
            )}
            
            {reservation.guests.length > 0 && (
                <div className="card-body">
                    {reservation.guests.map((guest) => (
                        <>
                            <div className="d-flex flex-stack ">  
                                <div className="symbol symbol-35px symbol-circle me-5" data-bs-toggle="tooltip" data-kt-initialized="1">
                                    <span className="symbol-label bg-light fw-bold">{getInitals(guest.name)}</span>
                                </div>
                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                    <div className="flex-grow-1 me-2">
                                        <a href="#" className="text-gray-800 text-hover-primary fs-5 fw-bold">{guest.name}</a>
                                    </div>
                                    <span className="text-muted fw-semibold d-block fs-6">{guest.phone}</span>
                                </div>
                            </div>
                            <div className="separator separator-dashed my-4"></div> 
                        </>
                    ))}                       
                </div>
            )}
            
            <div className="separator separator-dotted border-2 w-75 m-auto"></div>
            <label>
                <span className="section-ticket dots">
                    <span className="bg-light"></span>
                    <span className="bg-light"></span>
                </span>
            </label>
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <Link to={`/reserva/${business_branch.slug}/${id}/${token}`} className="btn btn-lg btn-light py-5 w-30">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
                        </svg>
                        Voltar</Link>
                    <a href={`https://api.whatsapp.com/send?text=Olá, por favor, confirme sua presença! ${getConfig('SHORT_URL')}/reserva/${business_branch.slug}/${reservation?.id}/${reservation?.token_rsvp}/rsvp`} className="btn btn-lg btn-success">Convidar<i className="bi bi-whatsapp ms-3"></i></a>
                </div>
            </div>
            </div></div>
        </>
    );
}

export default ReservationAdminGuests;