import React, { FC, useEffect, useState } from "react";
import { ResearchModel } from "../../models/Research";
import clsx from "clsx";
import moment from "moment";
import 'moment/locale/pt-br';
import { useAPI } from "../../hooks/useAPI";
import { BusinessBranchModel } from "../../models/BusinessModel";
import { Tooltip } from "bootstrap";
import { ReservationModel } from "../../models/Reservation";
import { useHelper } from "../../hooks/useHelper";
import { useReservation } from "../../hooks/useReservation";

interface Props {
    reservation: ReservationModel
    onClick: (reservation: ReservationModel) => void
    business_branch: BusinessBranchModel
    onApprove: (reservation: ReservationModel) => void
    onRefuse: (reservation: ReservationModel) => void
}

const ReservationRow: FC<Props> = ({ reservation, onClick, business_branch, onApprove, onRefuse }) => {
    const [data, setData] = useState(reservation);
    const { api } = useAPI();
    const { formatPhone, onlyNumbers } = useHelper();
    const { getReasons } = useReservation();

    useEffect(() => {
        Array.from(document.querySelectorAll(`.tooltip`))
            .forEach(node => node.remove());
        Array.from(document.querySelectorAll(`#research-${data.id} [data-bs-toggle="tooltip"]`))
            .forEach(node => new Tooltip(node));
    }, [data]);

    const getReservationBadge = (status: string) => {
        switch (status) {
            case "WAI":
                return (<span className="badge badge-warning text-uppercase fs-7">Pendente</span>);
            case "CON":
                return (<span className="badge badge-success text-uppercase fs-7">Confirmado</span>);
            case "REC":
                return (<span className="badge badge-danger text-uppercase fs-7">Recusada</span>);
            case "CAN":
                return (<span className="badge badge-danger text-uppercase fs-7">Cancelada</span>);
            default:
                return (<span className="badge badge-light text-uppercase fs-7">Completo</span>);
        }
    }

    const getReservationRowBg = (status: string) => {
        switch (status) {
            case "WAI":
                return "bg-warning";
            case "CON":
                return "bg-success";
            case "CAN":
                return "bg-danger";
            default:
                return "bg-secondary";
        }
    }

    return (
        <>
            <tr>
                <td className="row">
                    <div className="col-xl-5 col-md-6 col-12 mb-5">
                        <div className="d-flex align-items-center flex-wrap flex-stack">
                            <div className="align-items-center">
                                <div className="m-0">
                                    <div className="fs-4 fw-bolder text-uppercase">{moment(reservation.datetime).format("ddd, HH:mm")}H</div>
                                    <span className="fs-6 fw-bold text-gray-500 text-uppercase">{moment(reservation.datetime).format("DD MMM YYYY")}</span>
                                </div>
                            </div>
                            <div className="align-items-center">
                                <span className="badge badge-secondary fs-7">
                                    <i className="bi bi-person text-muted me-1 "></i>
                                    <span className="text-gray-600 fw-bolder">{reservation.person_number} PAX</span>
                                </span>
                                {" "}
                                {getReservationBadge(reservation.status)}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-6 col-12 mb-5">
                        <div className="d-flex align-items-center flex-wrap flex-stack">
                            <div className="align-items-center me-5 me-xl-13">
                                <span className="fs-5 fw-bold text-gray-800 mb-2"><span style={{textTransform: 'capitalize'}}>{reservation.owner.name}</span></span>
                                <div><a className="text-success" href={`https://web.whatsapp.com/send?phone=55${onlyNumbers(reservation.owner.phone)}`} target="_blank"><i className="bi bi-whatsapp fs-5 me-1 text-success"></i> {formatPhone(reservation.owner.phone)}</a></div>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                {reservation.status === "WAI" && (
                                    <a href="#"
                                    onClick={() => onApprove(reservation)}
                                    className={clsx("btn btn-icon", {
                                        "btn-success": reservation.status === 'WAI',
                                        "btn-bg-light btn-color-muted": reservation.status !== 'WAI'
                                    })} data-bs-original-title="Aprovar reserva" aria-label="Aprovar reserva" data-bs-toggle="modal" data-bs-target="#kt_modal_aceitar">
                                    <i className="bi bi-check-lg fs-2"></i></a>
                                )}
                                {reservation.status !== "WAI" && (
                                    <a href="#"
                                    className={clsx("btn btn-icon", {
                                        "btn-success": reservation.status === 'WAI',
                                        "btn-bg-light btn-color-muted": reservation.status !== 'WAI'
                                    })} data-bs-original-title="Aprovar reserva" aria-label="Aprovar reserva" data-bs-toggle="modal" data-bs-target="#kt_modal_aceitar">
                                    <i className="bi bi-check-lg fs-2"></i></a>
                                )}
                                {(reservation.status === "WAI" || reservation.status === "CON") && (
                                    <a href="#"
                                    onClick={() => onRefuse(reservation)}
                                    className={clsx("btn btn-icon", {
                                        "btn-danger": (reservation.status === 'WAI' || reservation.status === 'CON'),
                                    })} data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Recusar reserva" aria-label="Enviar Mensagem">
                                    <i className="bi bi-x-lg fs-2"></i></a>
                                )}
                                {(reservation.status === "REC" || reservation.status === "CAN") && (
                                    <a href="#"
                                    className={clsx("btn btn-icon", {
                                        "btn-bg-light btn-color-muted": (reservation.status === 'REC' || reservation.status === 'CAN')
                                    })} data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Recusar reserva" aria-label="Enviar Mensagem">
                                    <i className="bi bi-x-lg fs-2"></i></a>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-5 text-gray-700">
                        <span>{getReasons().map((reason) => {
                            if (reason.value === reservation.reason) {
                                return reason.label;
                            }
                        })}</span>
                        {reservation.comment && (
                            <>
                                {" "}
                                —
                                {" "}
                                <span className="fst-italic text-muted">"{reservation.comment}"</span>
                            </>
                        )}
                    </div>
                </td>
            </tr>            
        </>
    );
}

export default ReservationRow;