import axios, { AxiosResponse } from "axios";
import qs from "querystring";
import {useAuth} from "./useAuth";
import {useConfig} from "./useConfig";

export function useAPI() {
    const {getConfig} = useConfig();
    const API_URL = getConfig("API_URL");
    const {getToken} = useAuth();
    const timeout = 60000 * 5;

    const api = () => {
        const get = (path: string) => {
            return axios.get(`${API_URL}${path}`, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-AUTH-TOKEN": getToken(),
                    "Authorization": "Bearer " + getToken()
                },
                timeout: timeout
            });
        };

        const post = (path: string, data: any, sendAsJson: boolean = false) => {
            return axios.post(`${API_URL}${path}`, (!sendAsJson) ? qs.stringify(data) : data, {
                headers: {
                    "Content-Type": (!sendAsJson) ? "application/x-www-form-urlencoded" : "application/json",
                    "X-AUTH-TOKEN": getToken(),
                    "Authorization": "Bearer " + getToken()
                },
                timeout: timeout
            });
        };

        const put = (path: string, data: any, sendAsJson: boolean = false) => {
            return axios.put(`${API_URL}${path}`, (!sendAsJson) ? qs.stringify(data) : data, {
                headers: {
                    "Content-Type": (!sendAsJson) ? "application/x-www-form-urlencoded" : "application/json",
                    "X-AUTH-TOKEN": getToken(),
                    "Authorization": "Bearer " + getToken()
                },
                timeout: timeout
            });
        };

        const del = (path: string) => {
            return axios.delete(`${API_URL}${path}`, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-AUTH-TOKEN": getToken(),
                    "Authorization": "Bearer " + getToken()
                },
                timeout: timeout
            });
        };

        const upload = (path: string, file: any) => {
            let data = new FormData();
            data.append('file', file);

            return axios.post(`${API_URL}${path}`, data, {
                headers: {
                    "Content-Type": "multipart/formdata",
                    "Authorization": "Bearer " + getToken()
                },
                timeout: timeout * 5
            });
        };

        const download = (path: string) => {
            return new Promise<AxiosResponse>((resolve) => {
                axios.get(`${API_URL}${path}`, {
                    responseType:"blob",
                    headers: {
                        "Authorization": "Bearer " + getToken()
                    },
                })
                    .then(response => {
                        resolve(response);
                    })
                    .catch((err) => {
                        resolve(err.response);
                    });
            });
        }

        return {
            get,
            post,
            put,
            del,
            upload,
            download
        };
    };

    const doLogin = (email: string, senha: string, token: string) => {
        return api().post("/api/auth", {
            email: email,
            password: senha,
            'recaptcha-token': token
        });
    };

    return {
        doLogin,
        api
    };
}
