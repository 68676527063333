export function useConfig() {
    const configs: any = {
        'API_URL' : "https://nxp.digital",
        'SHORT_URL': "https://nxp.digital",
        // 'API_URL' : "http://localhost",
        // 'SHORT_URL': "http://localhost:3011"
    };

    const getConfig = (key: string) => {
        for(let i in configs) {
            if(i === key){
                return configs[i];
            }
        }

        return false
    }

    return {
        getConfig
    };
}
