import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAPI} from "../../../hooks/useAPI";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const initialValues = {
    email: '',
}

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Formato de e-mail inválido')
        .min(3, 'Mínimo de 3 caracteres')
        .max(50, 'Máximo de 50 caracteres')
        .required('Email é obrigatório'),
})

export function ForgotPassword() {
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const {api} = useAPI();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            if (!executeRecaptcha) {
                return;
            }
            setLoading(true);
            const token = await executeRecaptcha('forgetPassword');
            setHasErrors(undefined)
            api().post("/api/auth/forget-password", {'email': values.email, 'recaptcha-token': token}).then(
                resp => {
                    setLoading(false);
                    setHasErrors(false)
                }
            ).catch(() => {
                setHasErrors(true)
                setLoading(false)
                setSubmitting(false)
                setStatus('Desculpe, mas houve um erro, tente novamente.')
            });
        },
    })

    return (
        <>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='text-center mb-10'>
                    {/* begin::Title */}
                    <h1 className='text-dark mb-3'>Recuperar senha</h1>
                    {/* end::Title */}

                    {/* begin::Link */}
                    <div className='text-gray-400 fw-bold fs-4'>Digite seu e-mail abaixo</div>
                    {/* end::Link */}
                </div>

                {/* begin::Title */}
                {hasErrors === true && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>
                            Desculpe, mas houve um erro, tente novamente.
                        </div>
                    </div>
                )}

                {hasErrors === false && (
                    <div className='mb-10 bg-light-info p-8 rounded'>
                        <div className='text-info'>Você receberá um link para recuperar sua senha em seu e-mail.</div>
                    </div>
                )}
                {/* end::Title */}

                {/* begin::Form group */}
                <div className='fv-row mb-10'>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
                    <input
                        type='email'
                        placeholder=''
                        autoComplete='off'
                        {...formik.getFieldProps('email')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.email && formik.errors.email},
                            {
                                'is-valid': formik.touched.email && !formik.errors.email,
                            }
                        )}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.email}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button
                        type='submit'
                        id='kt_password_reset_submit'
                        className='btn btn-lg btn-primary fw-bolder me-4'
                    >
                        {!loading && <span className='indicator-label'>Recuperar</span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
              Um momento por favor...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                        )}
                    </button>
                    <Link to='/auth/login'>
                        <button
                            type='button'
                            id='kt_login_password_reset_form_cancel_button'
                            className='btn btn-lg btn-light-primary fw-bolder'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            Cancelar
                        </button>
                    </Link>{' '}
                </div>
                {/* end::Form group */}
            </form>
        </>
    )
}
