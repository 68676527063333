/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {

    return (
        <>
            <div className='menu-item'>
                <div className='menu-content pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>NAVEGUE POR AQUI</span>
                </div>
            </div>
            <AsideMenuItem
                to='/master/negocios'
                title='Negócios'
                fontIcon='bi-shop-window'
                icon='/media/icons/duotune/custom/bi-shop-window.svg'
            />
            <AsideMenuItem
                to='/master/usuarios'
                title='Usuários'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/custom/bi-people.svg'
            />
        </>
    )
}
