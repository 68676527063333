import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../../../_metronic/layout/core/LayoutProvider'
import {usePageData} from '../../../../../_metronic/layout/core/PageData'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'

type Props = {
  breadcrumb: Array<PageLink>
  title: string
}

const DefaultTitle: FC<Props> = ({children, breadcrumb, title}) => {
  const pageBreadcrumbs = breadcrumb;
  const pageTitle = title;

  const {config, classes} = useLayout()
  return (
    <div
      id='kt_page_title'
      className={clsx('page-title d-flex', classes.pageTitle.filter((value) => {return ["me-3", "mb-5", "mb-lg-0"].indexOf(value) === -1}).join(' '))}
    >

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                    className={clsx('breadcrumb-item', {
                      'text-dark': !item.isSeparator && item.isActive,
                      'text-muted': !item.isSeparator && !item.isActive,
                    })}
                    key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? (
                      <Link className='text-muted text-hover-primary' to={item.path}>
                        {item.title}
                      </Link>
                  ) : (
                      <span className='bullet bg-gray-200 w-5px h-2px'></span>
                  )}
                </li>
              ))}
              <li className='breadcrumb-item text-dark'>{pageTitle}</li>
            </ul>
          </>
        )}
    </div>
  )
}

export {DefaultTitle}
