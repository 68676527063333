import React, {FC} from "react";
import BackButton from "./BackButton";

interface Props {
    onTryAgain: () => void
}

const ErrorLoading: FC<Props> = ({onTryAgain}) => {
    return (
        <>
            <h1>Algo deu errado!</h1> <br />
            <a href="#" style={{'marginRight': '10px'}} className='btn btn-sm btn-primary' onClick={(e) => {
                e.preventDefault();
                onTryAgain();
            }}>Tentar novamente</a>
            <BackButton />
        </>
    );
}

export default ErrorLoading;