export default function UserReducer(state: any, action: any){
    switch (action.type) {
        case "setUser":
            return {...state, user: action.user};
        case "setLoaded":
            return {...state, loaded: action.loaded};
        case "setLoading":
            return {...state, loadingUser: action.loadingUser};
        default:
            return state;
    }
}