import React, { FC, useContext, useEffect, useState } from "react";
import { PageProps } from "../../models/Page";
import { useAPI } from "../../hooks/useAPI";
import Loading from "../../components/Loading";
import ErrorLoading from "../../components/ErrorLoading";
import { useConfig } from "../../hooks/useConfig";
import clsx from "clsx";
import { PaginationCustomers } from "../../models/CustomerModel";
import CustomerRow from "./CustomerRow";
import CustomerAddModal from "./CustomerAddModal";
import { useHelper } from "../../hooks/useHelper";

const CustomersPage: FC<PageProps> = ({ updateToolbar, business_branch }) => {
    const [search, setSearch] = useState("");
    const [birthDateFrom, setBirthDateFrom] = useState<string | undefined>("");
    const [birthDateTo, setBirthDateTo] = useState<string | undefined>("");
    const [visitDateFrom, setVisitDateFrom] = useState<string | undefined>("");
    const [visitDateTo, setVisitDateTo] = useState<string | undefined>("");
    const [visitCount, setVisitCount] = useState<number | string>("");
    const [showAddModal, setShowAddModal] = useState(false);
    const { downloadFile } = useHelper();

    useEffect(() => {
        updateToolbar({
            title: "Gestão de Clientes",
            breadcrumbs: [
                {
                    title: business_branch.name,
                    path: '#',
                    isSeparator: false,
                    isActive: false,
                },
                {
                    title: '',
                    path: '',
                    isSeparator: true,
                    isActive: false,
                },
            ],
            buttons: []
        });
        loadcustomers("", "", "", "", "", "");
    }, []);

    const [loading, setLoading] = useState(true);
    const [loadingFile, setLoadingFile] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [customers, setCustomers] = useState<PaginationCustomers | undefined>(undefined);
    const { api } = useAPI();
    const [page, setPage] = useState(1);

    const loadcustomers = (search: string, birthDateFrom: string | undefined, birthDateTo: string | undefined, visitDateFrom: string | undefined, visitDateTo: string | undefined, visitCount: string | number) => {
        setLoading(true);
        setErrorLoading(false);
        api().get(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/customer?page=${page}&search=${search}&visitCount=${visitCount}&birthDateFrom=${birthDateFrom}&birthDateTo=${birthDateTo}&visitDateFrom=${visitDateFrom}&visitDateTo=${visitDateTo}`).then((resp) => {
            setLoading(false);
            setCustomers(resp.data);
        }).catch(() => {
            setLoading(false);
            setErrorLoading(true);
        })
    }

    const exportResult = (search: string, birthDateFrom: string | undefined, birthDateTo: string | undefined, visitDateFrom: string | undefined, visitDateTo: string | undefined, visitCount: string | number) => {
        setLoadingFile(true);
        setErrorLoading(false);
        downloadFile(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/customer?page=${page}&search=${search}&visitCount=${visitCount}&birthDateFrom=${birthDateFrom}&birthDateTo=${birthDateTo}&visitDateFrom=${visitDateFrom}&visitDateTo=${visitDateTo}&export=true`, "clientes.csv").then((resp) => {
            setLoadingFile(false);
        }).catch(() => {
            setLoadingFile(false);
            setErrorLoading(true);
        })
    }

    const getPages = (last_page: number): Array<number> => {
        let pages: Array<number> = [];
        for (let i = 0; i < last_page; i++) {
            pages.push(i + 1);
        }
        return pages;
    }

    const clearAll = () => {
        setSearch("");
        setBirthDateFrom("");
        setBirthDateTo("");
        setVisitDateFrom("");
        setVisitDateTo("");
        setVisitCount("");
        loadcustomers("", "", "", "", "", "");
    }

    useEffect(() => {
        loadcustomers(search, birthDateFrom, birthDateTo, visitDateFrom, visitDateTo, visitCount);
    }, [page]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            loadcustomers(search, birthDateFrom, birthDateTo, visitDateFrom, visitDateTo, visitCount);
        }, 500);

        return () => clearTimeout(delayDebounceFn); // Cleanup the timeout if the component unmounts or query changes
    }, [search]);


    return (
        <>
            {loadingFile && (
                <Loading fullscreen={true} />
            )}
            {showAddModal && (<CustomerAddModal
                onClose={() => { setShowAddModal(false); }}
                business_branch={business_branch}
                onSaveUser={() => {
                    setShowAddModal(false);
                    clearAll();
                }}
            />)}
            {errorLoading && (<ErrorLoading onTryAgain={() => {
                loadcustomers(search, birthDateFrom, birthDateTo, visitDateFrom, visitDateTo, visitCount);
            }} />)}
            {!errorLoading && (
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10" style={{
                        "position": (loading) ? "relative" : undefined
                    }}>
                        <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                            <div id="kt_app_toolbar_container" className="app-container d-flex flex-stack ">
                                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                                    <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                                        Gestão de Clientes
                                    </h1>
                                </div>
                                <div className="d-flex align-items-center gap-2 gap-lg-3">
                                    <div className="m-0">
                                        <a href="#" className="btn btn-sm btn-flex btn-secondary fw-bold" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <i className="ki-duotone ki-filter fs-6 text-muted me-1"><span className="path1"></span><span className="path2"></span></i>
                                            Filtrar
                                        </a>
                                        <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_66bb9a37c5d0a">
                                            <div className="px-7 py-5">
                                                <div className="fs-5 text-gray-900 fw-bold">Filtrar</div>
                                            </div>
                                            <div className="separator border-gray-200"></div>
                                            <div className="px-7 py-5">
                                                <div className="d-flex col-12 col-sm-12 flex-wrap mb-5">
                                                    <label className="form-label">Aniversário De:</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="De"
                                                        value={birthDateFrom}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setBirthDateFrom(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex col-12 col-sm-12 flex-wrap mb-5">
                                                    <label className="form-label">Aniversário Até:</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Até"
                                                        value={birthDateTo}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setBirthDateTo(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex col-12 col-sm-12 flex-wrap mb-5">
                                                    <label className="form-label">Visita De:</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="De"
                                                        value={visitDateFrom}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setVisitDateFrom(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex col-12 col-sm-12 flex-wrap mb-5">
                                                    <label className="form-label">Visita Até:</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Até"
                                                        value={visitDateTo}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setVisitDateTo(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex col-12 col-sm-12 flex-wrap mb-5">
                                                    <label className="form-label">Número de visitas:</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Número de visitas"
                                                        value={visitCount}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setVisitCount(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <button type="reset" className="btn btn-sm btn-danger me-2" data-kt-menu-dismiss="true" onClick={clearAll}>Limpar</button>

                                                    <button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true" onClick={() => loadcustomers(search, birthDateFrom, birthDateTo, visitDateFrom, visitDateTo, visitCount)}>Aplicar</button>
                                                </div>
                                            </div>
                                        </div></div>
                                    <a href="#" className="btn btn-sm fw-bold btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" onClick={() => exportResult(search, birthDateFrom, birthDateTo, visitDateFrom, visitDateTo, visitCount)}>
                                        Exportar</a>
                                    <a href="#" className="btn btn-sm fw-bold btn-success" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" onClick={() => setShowAddModal(true)}>
                                        Adicionar Novo</a>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap flex-stack mb-10">
                            <div className="d-flex col-12 col-sm-12 flex-wrap">
                                <div data-kt-search-element="form" className="w-100 position-relative me-0 mb-5 mb-sm-0">
                                    <span
                                        className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2"
                                                rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                            <path
                                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                fill="black" />
                                        </svg>
                                    </span>
                                    <form action="" onSubmit={(e) => {
                                        e.preventDefault();
                                        loadcustomers(search, birthDateFrom, birthDateTo, visitDateFrom, visitDateTo, visitCount);
                                    }}>
                                        <input type="text" className="form-control ps-15" id="search-category" name="search" value={search}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setSearch(e.target.value);
                                            }}
                                            placeholder="Nome ou Telefone" data-kt-search-element="input" />
                                    </form>
                                    <span className="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5"
                                        data-kt-search-element="spinner">
                                        <span
                                            className="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header align-items-center py-5 gap-5">
                                <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Clientes</h1>
                            </div>
                            <div className="card-body p-0">
                                {loading && (
                                    <div style={{
                                        "position": "relative",
                                        "minHeight": "100px"
                                    }}>
                                        <Loading />
                                    </div>
                                )}
                                {!loading && (
                                    <div className="table-responsive">
                                        <table className="table table-row-dashed table-row-gray-300 align-middle fs-6 gy-5 my-0">
                                            <thead className="text-uppercase text-muted">
                                                <tr>
                                                    <th style={{ 'paddingLeft': '1.5rem' }}>Cliente</th>
                                                    <th>Contato</th>
                                                    <th>Data Nascimento</th>
                                                    <th>Cadastro</th>
                                                    <th>Visitas</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {customers?.data.map((item) => (
                                                    <CustomerRow
                                                        customer={item} onClick={(customer) => {
                                                        }}
                                                        business_branch={business_branch}
                                                        key={`${item.id}`}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                        {!loading && (
                            <div className="d-flex flex-stack flex-wrap pt-10">
                                <div className="fs-6 fw-bold text-gray-700">Mostrando {customers?.from} a {customers?.to} de {customers?.total}</div>
                                <ul className="pagination">
                                    {page > 1 && (
                                        <li className="page-item previous">
                                            <a
                                                href=""
                                                className="page-link"
                                                onClick={(e) => {
                                                    setPage(page - 1);
                                                    e.preventDefault();
                                                }}
                                            >
                                                <i className="previous"></i>
                                            </a>
                                        </li>
                                    )}
                                    {customers && getPages(customers?.last_page).map((i) => {
                                        return (
                                            <li className={clsx("page-item", {
                                                "active": i === customers?.current_page
                                            })}>
                                                <a
                                                    href=""
                                                    className="page-link"
                                                    onClick={(e) => {
                                                        setPage(i);
                                                        e.preventDefault();
                                                    }}
                                                >{i}</a>
                                            </li>
                                        );
                                    })}
                                    {customers && page < customers?.last_page && (
                                        <li className="page-item next">
                                            <a
                                                href=""
                                                className="page-link"
                                                onClick={(e) => {
                                                    setPage(page + 1);
                                                    e.preventDefault();
                                                }}
                                            >
                                                <i className="next"></i>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default CustomersPage;