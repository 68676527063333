import React, {FC} from "react";
import moment from "moment";
import { BusinessBranchModel } from "../../../models/BusinessModel";
import { ReservationModel } from "../../../models/Reservation";
import { useStateList } from "../../../hooks/useStateList";

interface Props {
    onSubmit: (data: any) => void,
    onBack: () => void,
    business_branch: BusinessBranchModel,
    reservation: ReservationModel
}
const ReservationDataConfirm: FC<Props> = ({onSubmit, business_branch, reservation, onBack}) => {
    const {getStateShort} = useStateList();
    
    return (
        <>
            <div className="card-body text-center pb-5 px-10">
                    <h1 className="fs-1 primary-brand-color mb-0 fw-normal">Verifique os dados<br />e clique em confirmar</h1>
				</div>
                
				<div className="separator separator-dotted border-2 w-75 m-auto"></div>
				<label>
					<span className="section-ticket dots">
						<span className="bg-light"></span>
						<span className="bg-light"></span>
					</span>
				</label>
				<div className="card-body">
					<div className="row">
						<div className="col-12 mb-5">
							<div className="border border-gray-300 border-dashed p-4 rounded-3 bg-light">
								<div className="svg-icon svg-icon-5 svg-icon-success text-muted mb-5">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-week" viewBox="0 0 16 16">
										<path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
										<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
									</svg>
								</div>
								<div className="fw-semibold fs-6 text-gray-400">Data</div>
								<span className="text-gray-800 fs-6 fw-bolder">
                                    <span style={{'textTransform': 'capitalize'}}>{moment(reservation.date, "YYYY-MM-DD").format("dddd")}</span>
                                    {moment(reservation.date, "YYYY-MM-DD").format(", DD [de] MMMM [de] YYYY")}
                                    </span>
							</div>
						</div>
						<div className="col-6">
							<div className="border border-gray-300 border-dashed p-4 rounded-3 bg-light">
								<div className="svg-icon svg-icon-5 svg-icon-success text-muted mb-5">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
										<path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
										<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
									  </svg>
								</div>
								<div className="fw-semibold fs-6 text-gray-400">Horário</div>
								<span className="text-gray-800 fs-6 fw-bolder">{reservation.time}h</span>
							</div>
						</div>
						<div className="col-6">
							<div className="border border-gray-300 border-dashed py-4 ps-4 rounded-3 bg-light">
								<div className="svg-icon svg-icon-3 svg-icon-success text-muted mb-4">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
                                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                                    </svg>
								</div>
								<div className="fw-semibold fs-6 text-gray-400">Pessoas</div>
								<span className="text-gray-800 fs-6 fw-bolder">{reservation.person_number}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="separator separator-dotted border-2 w-75 m-auto"></div>
				<label>
					<span className="section-ticket dots">
						<span className="bg-light"></span>
						<span className="bg-light"></span>
					</span>
				</label>
				<div className="card-body">
					<div className="row">
						<div className="col-12">
							<div className="border border-gray-300 border-dashed p-4 rounded-3">
								<div className="d-flex align-items-center">
									<div className="d-flex align-items-center flex-grow-1">
										<div className="d-flex flex-column">
											<div className="fw-bold fs-6 text-gray-400">Nome</div>
											<span className="text-gray-800 fs-6 fw-bolder">{reservation.name}</span>
										</div>
									</div>
									<div className="my-0">
										<span className="svg-icon svg-icon-2 text-gray-300">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-badge" viewBox="0 0 16 16">
												<path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
												<path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z"/>
											  </svg>
										</span>
									</div>
								</div>
							</div>
							<div className="border border-gray-300 border-dashed py-4 px-4 rounded-3 mt-3">
								<div className="d-flex align-items-center">
									<div className="d-flex align-items-center flex-grow-1">
										<div className="d-flex flex-column">
											<div className="fw-bold fs-6 text-gray-400">Telefone</div>
											<span className="text-gray-800 fs-6 fw-bolder">{reservation.phone}</span>
										</div>
									</div>
									<div className="my-0">
										<span className="svg-icon svg-icon-2 text-gray-300">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
												<path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
											  </svg>
										</span>
									</div>
								</div>
							</div>
                            <div className="border border-gray-300 border-dashed py-4 px-4 rounded-3 mt-3">
								<div className="d-flex align-items-center">
									<div className="d-flex align-items-center flex-grow-1">
										<div className="d-flex flex-column">
											<div className="fw-bold fs-6 text-gray-400">Email</div>
											<span className="text-gray-800 fs-6 fw-bolder">{reservation.email}</span>
										</div>
									</div>
									<div className="my-0">
										<span className="svg-icon svg-icon-2 text-gray-300">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                                            </svg>
										</span>
									</div>
								</div>
							</div>
							<div className="border border-gray-300 border-dashed py-4 px-4 rounded-3 mt-3">
								<div className="d-flex align-items-center">
									<div className="d-flex align-items-center flex-grow-1">
										<div className="d-flex flex-column">
											<div className="fw-bold fs-6 text-gray-400">Local</div>
											<span className="text-gray-800 fs-6 fw-bolder">{business_branch.branch} - {business_branch.name}</span>
											<span className="text-gray-700 fs-6 fw-normal">{`${business_branch.address.street}, ${(business_branch.address.complement ? business_branch.address.complement + ", " : "")} ${business_branch.address.number} - ${business_branch.address.district} , ${business_branch.address.city.name} ${getStateShort(business_branch.address.city.state_id)}, ${business_branch.address.zipcode}`}</span>
										</div>
									</div>
									<div className="my-0">
										<span className="svg-icon svg-icon-2 text-gray-300">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
												<path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
												<path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
											</svg>
										</span>
									</div>
								</div>
							</div>
                            {reservation.comment && (
                                <div className="border border-gray-300 border-dashed py-4 px-4 rounded-3 mt-3">
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex align-items-center flex-grow-1">
                                            <div className="d-flex flex-column">
                                                <div className="fw-bold fs-6 text-gray-400">Observação</div>
                                                <span className="text-gray-700 fs-6 fw-normal">{reservation.comment}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
						</div>
					</div>
				</div>
				<div className="separator separator-dotted border-2 w-75 m-auto"></div>
				<label>
					<span className="section-ticket dots">
						<span className="bg-light"></span>
						<span className="bg-light"></span>
					</span>
				</label>
				<div className="card-body">
                    <div className="d-flex justify-content-between">
                        <a 
                            href="" 
                            className="btn btn-lg btn-light py-5 w-30"
                            onClick={(e) => {
                                onBack();
                                e.preventDefault();
                            }}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                            </svg>
                            Voltar</a>
				        <a 
                            href="" 
                            className="btn btn-lg btn-success shadow py-5 w-70"
                            onClick={(e) => {
                                onSubmit(reservation);
                                e.preventDefault();
                            }}
                            >Confirmar</a>
                    </div>
				</div>
        </>
    );
}

export default ReservationDataConfirm;