import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import clsx from "clsx";
import { useReservation } from "../../../hooks/useReservation";
import moment from "moment";
import { BusinessBranchModel } from "../../../models/BusinessModel";
import Loading from "../../../components/Loading";
import { ReservationModel } from "../../../models/Reservation";
import { useHelper } from "../../../hooks/useHelper";
import { CustomerModel } from "../../../models/CustomerModel";
import { useCustomer } from "../../../hooks/useCustomer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const CommentSchema = Yup.object().shape({
    name: Yup.string()
        .required('Nome é obrigatório.'),
    phone: Yup.string()
        .required('Telefone é obrigatório.'),
    email: Yup.string()
        .email('Email inválido.')
        .required('Email é obrigatório.'),
    birth_date: Yup.string()
        .required('Data de nascimento é obrigatório.'),
    customer_id: Yup.number()
});

interface Props {
    onSubmit: (data: any) => void,
    business_branch: BusinessBranchModel,
    customer?: CustomerModel
}
const CustomerSignupDataForm: FC<Props> = ({ onSubmit, business_branch, customer }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [loading, setLoading] = useState(false);
    const helper = useHelper();
    const [showAllFields, setShowAllFields] = useState(false);

    const [phone, setPhone] = useState((customer) ? customer.phone : "");    
    const customerApi = useCustomer();

    const formik = useFormik({
        initialValues: {
            name: (customer) ? customer.name : "",
            email: (customer) ? customer.email : "",
            phone: (customer) ? customer.phone : "",
            birth_date: (customer) ? customer.birth_date : "",            
            customer_id: undefined
        },
        validationSchema: CommentSchema,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values: any, { setStatus, setSubmitting }) => {
            onSubmit({                
                ...values,
            });
        },
    });

    useEffect(() => {
        const formikPhone = helper.onlyNumbers(formik.values.phone);

        if (formikPhone !== phone && formikPhone.length >= 11) {
            setPhone(formikPhone);
            setShowAllFields(false);
            
            if (!executeRecaptcha) {
                return;
            }
            setLoading(true);
            executeRecaptcha('customer').then((token) => {
                return customerApi.find(formikPhone, token).then((resp) => {
                    console.log(resp)
                    formik.setFieldValue("name", resp?.name);
                    formik.setFieldValue("email", resp?.email);
                    formik.setFieldValue("birth_date", (resp) ? moment(resp?.birth_date).format("DD/MM/yyyy") : "");
                    formik.setFieldValue("customer_id", resp?.id);
                    setLoading(false);
                    setShowAllFields(true);
                }).catch(() => {
                    setLoading(false);
                    formik.setFieldValue("name", "");
                    formik.setFieldValue("email", "");
                    formik.setFieldValue("birth_date", "");
                    formik.setFieldValue("customer_id", "");
                    setShowAllFields(true);
                });
            }, () => {
                setLoading(false);
                formik.setFieldValue("name", "");
                formik.setFieldValue("email", "");
                formik.setFieldValue("birth_date", "");
                formik.setFieldValue("customer_id", "");
                setShowAllFields(true);
            });
        }
    }, [formik.values]);

    return (
        <>
            {loading && (<Loading fullscreen={true} />)}
            <form onSubmit={formik.handleSubmit} noValidate={true}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 mb-5">
                            <span className="text-gray-700 fw-bold fs-6 d-block mb-2 required">Telefone</span>
                            <InputMask
                                mask={'(99) 99999-9999'}
                                type="text"
                                placeholder="Digite seu telefone"
                                {...formik.getFieldProps('phone')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    { 'is-invalid': formik.touched.phone && formik.errors.phone },
                                    {
                                        'is-valid': formik.touched.phone && !formik.errors.phone,
                                    }
                                )}
                            />
                            {formik.touched.phone && formik.errors.phone && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert'>{formik.errors.phone}</span>
                                </div>
                            )}
                        </div>
                        {showAllFields && (
                            <>
                                <div className="col-12 mb-5">
                                    <span className="text-gray-700 fw-bold fs-6 d-block mb-2 required">Nome</span>
                                    <input
                                        type="email"
                                        placeholder="Digite seu nome"
                                        maxLength={255}
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                            {
                                                'is-valid': formik.touched.name && !formik.errors.name,
                                            }
                                        )}
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.name}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 mb-5">
                                    <span className="text-gray-700 fw-bold fs-6 d-block mb-2 required">Email</span>
                                    <input
                                        type="email"
                                        placeholder="Digite seu email"
                                        maxLength={255}
                                        {...formik.getFieldProps('email')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            { 'is-invalid': formik.touched.email && formik.errors.email },
                                            {
                                                'is-valid': formik.touched.email && !formik.errors.email,
                                            }
                                        )}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.email}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 mb-5">
                                    <span className="text-gray-700 fw-bold fs-6 d-block mb-2 required">Data de Nascimento</span>
                                    <InputMask
                                        mask={'99/99/9999'}
                                        type="text"
                                        placeholder="Data de nascimento"
                                        {...formik.getFieldProps('birth_date')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            { 'is-invalid': formik.touched.birth_date && formik.errors.birth_date },
                                            {
                                                'is-valid': formik.touched.birth_date && !formik.errors.birth_date,
                                            }
                                        )}
                                    />
                                    {formik.touched.birth_date && formik.errors.birth_date && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.birth_date}</span>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="separator separator-dotted border-2 w-75 m-auto"></div>
                <label className="d-block">
                    <span className="section-ticket dots">
                        <span className="bg-light"></span>
                        <span className="bg-light"></span>
                    </span>
                </label>
                <div className="card-body">
                    <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-lg btn-primary py-5 shadow">
                            Avançar
                            <span className="svg-icon svg-icon-2 ms-1 me-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default CustomerSignupDataForm;