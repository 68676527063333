import React, {Suspense, FC} from 'react';
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'

import {
  BrowserRouter
} from "react-router-dom";

import {Routes} from './routing/Routes'
import {PageDataProvider} from "./components/PageDataProvider";
import {UserContextProvider} from "./providers/UserContextProvider";

type Props = {
  basename: string 
}

const AuthInit: FC = (props) => {

    return <>{props.children}</>;
}

const App: React.FC<Props> = ({basename}) => {
  return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter basename={basename}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <PageDataProvider>
                    <UserContextProvider>
                        <Routes />
                    </UserContextProvider>
                </PageDataProvider>
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </BrowserRouter>
      </Suspense>
  );
}

export default App;
