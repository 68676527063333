import React, {Suspense, lazy, FC, useContext, useEffect} from 'react'
import {Redirect, Route, Switch, } from 'react-router-dom'
import {FallbackView} from '../partials/FallbackView'
import {BusinessesContext} from "../providers/BusinessesContextProvider";
import {BusinessBranchesContext} from "../providers/BusinessBranchesContextProvider";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import {
    GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';
import ResearchStartCard from "../modules/site/components/ResearchStartCard";
import ResearchDataCard from "../modules/site/components/ResearchDataCard";
import ResearchNoteCard from "../modules/site/components/ResearchNoteCard";
import ResearchCommentCard from "../modules/site/components/ResearchCommentCard";
import ReservationPage from '../modules/site/ReservationPage';
import ReservationAdminPage from '../modules/site/ReservationAdminPage';
import CustomerSignupPage from '../modules/site/CustomerSignupPage';

type Props = {}

const SiteRoutes: FC<Props> = () => {
    const SiteProfilePage = lazy(() => import('../modules/site/ProfilePage'));
    const SiteMenuPage = lazy(() => import('../modules/site/MenuPage'));
    const ResearchPage = lazy(() => import('../modules/site/ResearchPage'));
    const {business_branch} = useContext(BusinessBranchesContext);
    const reCaptchaKey = "6LfU_7ofAAAAAOHSmjU7CE1it8IuXsb2spJVD2fN";

    useEffect(() => {
        if (business_branch !== undefined) {
            if (business_branch.color_primary !== null) {
                document.documentElement.style.setProperty('--primary-brand-color', business_branch.color_primary);
            }

            if (business_branch.color_secondary !== null) {
                document.documentElement.style.setProperty('--secondary-brand-color', business_branch.color_secondary);
            }

            if(business_branch.google_analytics !== null) {
                ReactGA.initialize(business_branch.google_analytics);
            }

            if(business_branch.facebook_pixel !== null) {
                const options = {
                    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                    debug: false, // enable logs
                };
                ReactPixel.init(business_branch.facebook_pixel, undefined, options);
            }
        }
        document.getElementById("root")?.style.setProperty("display", "block");
    }, [business_branch]);

    return (
        <Suspense fallback={<FallbackView/>}>
            <BusinessesContext.Consumer>
                {({business}) => (
                    <BusinessBranchesContext.Consumer>
                        {({business_branch}) => (
                            <Switch>
                                <Route path='/profile/:business_branch_slug'>
                                    <SiteProfilePage
                                        business_branch={business_branch}
                                        filterMenus={false}
                                    />
                                </Route>
                                <Route path='/perfil/:business_branch_slug'>
                                    <SiteProfilePage
                                        business_branch={business_branch}
                                        filterMenus={false}
                                    />
                                </Route>
                                <Route path='/cardapios/:business_branch_slug' exact>
                                    <SiteProfilePage
                                        business_branch={business_branch}
                                        filterMenus={true}
                                    />
                                </Route>
                                <Route path='/cardapios/:business_branch_slug/:id' exact>
                                    <SiteMenuPage
                                        business_branch={business_branch}
                                    />
                                </Route>
                                <Route path='/pesquisa/:business_branch_slug' exact>
                                    <GoogleReCaptchaProvider
                                        reCaptchaKey={reCaptchaKey}
                                    >
                                        <ResearchPage
                                            business_branch={business_branch}
                                        />
                                    </GoogleReCaptchaProvider>
                                </Route>
                                <Route path='/reserva/:business_branch_slug' exact>
                                    <GoogleReCaptchaProvider
                                        reCaptchaKey={reCaptchaKey}
                                    >
                                        <ReservationPage
                                            business_branch={business_branch}
                                        />
                                    </GoogleReCaptchaProvider>
                                </Route>
                                <Route path='/reserva/:business_branch_slug/:id/:token'>
                                    <ReservationAdminPage
                                        business_branch={business_branch}
                                    />
                                </Route>
                                <Route path='/cadastro/:business_branch_slug' exact>
                                    <GoogleReCaptchaProvider
                                        reCaptchaKey={reCaptchaKey}
                                    >
                                        <CustomerSignupPage
                                            business_branch={business_branch}
                                        />
                                    </GoogleReCaptchaProvider>
                                </Route>
                                <Route>
                                    <SiteProfilePage
                                        business_branch={business_branch}
                                        filterMenus={false}
                                    />
                                </Route>
                                <Redirect to='/error/404'/>
                            </Switch>
                        )}
                    </BusinessBranchesContext.Consumer>
                )}
            </BusinessesContext.Consumer>
        </Suspense>
    )
}

export {SiteRoutes};
