import React, {FC, useEffect, useState} from "react";
import {Route, Switch, } from 'react-router-dom'
import {BusinessBranchModel} from "../../models/BusinessModel";
import Loading from "../../components/Loading";
import {useAPI} from "../../hooks/useAPI";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { ReservationModel } from "../../models/Reservation";
import { useParams } from "react-router-dom";
import ReservationAdminOwner from "./components/ReservationAdminOwner";
import ReservationAdminGuests from "./components/ReservationAdminGuests";
import ReservationAdminRSVP from "./components/ReservationAdminRSVP";
import { useConfig } from "../../hooks/useConfig";

interface Props {
    business_branch: BusinessBranchModel
}

type Params = {
    id: string,
    token: string
}

const ReservationAdminPage: FC<Props> = ({business_branch}) => {
    const {id, token} = useParams<Params>();
    const {api} = useAPI();
    const [reservation, setReservation] = useState<ReservationModel | undefined>(undefined);
    const [loading, setLoading] = useState(true);    
    const {getConfig} = useConfig();

    useEffect(() => {
        api()
            .get(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation/${id}/${token}`)
            .then((resp) => {
                setLoading(false);
                setReservation(resp.data);
            });
    },[]);

    useEffect(() => {
        if(business_branch.google_analytics !== null) {
            ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
        }
        if(business_branch.facebook_pixel !== null) {
            ReactPixel.pageView();
        }
    }, []);

    useEffect(() => {
        try {
            document.title = `${business_branch.branch} ${business_branch.name} - nexp digital`;            
        } catch (e) {}
    }, [business_branch]);

    useEffect(() => {
        document.getElementById("body-custom")?.classList.add('bg-light');
    }, [business_branch]);

    return (
        <>
            {loading && (<Loading fullscreen={true} />)}     
            {reservation !== undefined && (
            <Switch>
                {token !== reservation.token_rsvp && (
                    <>
                        <Route path='/reserva/:business_branch_slug/:id/:token' exact>
                            <ReservationAdminOwner 
                                business_branch={business_branch}
                                reservation={reservation}
                            />
                        </Route>
                        <Route path='/reserva/:business_branch_slug/:id/:token/guests' exact>
                            <ReservationAdminGuests 
                                business_branch={business_branch}
                                reservation={reservation}
                            />
                        </Route>
                    </>
                )}
                <Route path='/reserva/:business_branch_slug/:id/:token/rsvp' exact>
                    <ReservationAdminRSVP 
                        business_branch={business_branch}
                        reservation={reservation}
                    />
                </Route>
            </Switch>
            )}
        </>
    );
}

export default ReservationAdminPage;