import React, { FC, useEffect, useReducer, useState } from "react";
import { MenuCategoryModel, MenuModel, MenuSubCategoryModel } from "../../../models/MenuModel";
import clsx from "clsx";
import { DefaultScheduleMeta, ScheduleModel } from "../../../models/Schedule";
import ScheduleMini from "../../../components/ScheduleMini";
import FieldWithLanguage from "../../../components/FieldWithLanguage";
import { useAPI } from "../../../hooks/useAPI";
import { Alert } from "react-bootstrap-v5";
import cloneDeep from 'lodash/cloneDeep';
import { MenuCategoryReducer } from "../../../reducers/MenuReducer";
import { ReservationModel } from "../../../models/Reservation";
import { BusinessBranchModel } from "../../../models/BusinessModel";
import { useHelper } from "../../../hooks/useHelper";
import moment from "moment";

interface Props {
    reservation: ReservationModel
    business_branch: BusinessBranchModel
    onHide: () => void
    onSave: () => void
}

const ConfirmModal: FC<Props> = ({
    reservation,
    business_branch,
    onHide,
    onSave
}) => {
    const { api } = useAPI();

    const [isSaving, setIsSaving] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [showError, setShowError] = useState(false);
    const [notify, setNotify] = useState(true);
    const { formatPhone, onlyNumbers } = useHelper();

    const onSubmit = (schedule?: ScheduleModel) => {
        setHasSubmitted(false);
        setIsSaving(true);
        setShowError(false);
        api()
            .put(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation/${reservation?.id}/approve-admin`, {})
            .then((resp) => {
                setIsSaving(false);
                if (resp.data.success) {
                    onSave()
                } else {
                    setShowError(true);
                }
            })
            .catch(() => {
                setIsSaving(false);
                setShowError(true);
            });
        /*const menuData = { ...data, schedule: schedule, menu_id: menu.id, owner_category_id: owner_category_id };
        api()
            .post(`/api/business/${business_id}/branch/${business_branch_id}/menu/${menu.id}/category/${menu_category?.id}`, menuData, true)
            .then((resp) => {
                setIsSaving(false);
                onSave({ ...resp.data.menu_category, itens: [] });
            })
            .catch(() => {
                setIsSaving(false);
                setShowError(true);
            })*/
    }

    return (
        <>
            <div className="modal fade show">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header" id="kt_modal_add_user_header">
                            <h2 className="fw-bolder">Confirmação de Reserva</h2>
                            <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={isSaving ? () => {} : onHide}><span className="svg-icon svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                            </svg></span></div>
                        </div>

                        <div className="modal-body">
                            <div className="d-flex flex-column flex-row-fluid mb-5">
                                {showError && (<Alert variant={'danger'}>Houve um erro ao salvar, tente novamente.</Alert>)}
                            </div>
                            <div className="text-center mb-13">
                                Está quase lá! Confirme o compromisso com <span style={{textTransform: 'capitalize'}}>{reservation.owner.name}</span> para {" "}
                                <span style={{textTransform: 'capitalize'}}>{moment(reservation.datetime).format("dddd")}</span>{" "}
                                {moment(reservation.datetime).format("DD")}{" "}
                                <span style={{textTransform: 'capitalize'}}>{moment(reservation.datetime).format("MMM")}</span>{" "}
                                {moment(reservation.datetime).format("[às] HH:mm")}h.
                            </div>
                            <div className="mb-15 fv-row fv-plugins-icon-container">
                                <div className="d-flex flex-stack">
                                    <div className="fw-semibold me-5">
                                        <label className="fs-6">Notificar por Whatsapp?</label>
                                        <div className="fs-7 text-gray-400">Selecione “Sim” para abrir o WhatsApp e enviar a confirmação.</div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label className="form-check form-check-custom form-check-solid me-10">
                                            <input className="form-check-input h-20px w-20px" type="radio" name="notifications[]" checked={notify} onClick={isSaving ? () => {} : () => setNotify(true)} />
                                            <span className="form-check-label fw-semibold">Sim</span>
                                        </label>
                                        <label className="form-check form-check-custom form-check-solid">
                                            <input className="form-check-input h-20px w-20px" type="radio" name="notifications[]" checked={!notify} onClick={isSaving ? () => {} : () => setNotify(false)} />
                                            <span className="form-check-label fw-semibold">Não</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                            <div className="text-center">
                                <button type="button" className="btn btn-light me-3" onClick={isSaving ? () => {} : onHide}>Fechar</button>
                                <a href={notify ? `https://web.whatsapp.com/send?phone=55${onlyNumbers(reservation.owner.phone)}` : '#'} target={notify ? '_blank' : '_self'} className="btn btn-success" data-kt-indicator={(isSaving) ? "on" : "off"}
                                    onClick={isSaving ? () => {} : (e) => {
                                        onSubmit();
                                    }}>
                                    <span className="indicator-label">Confirmar</span>
                                    <span className="indicator-progress">Um momento...
                                        <span
                                            className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConfirmModal;