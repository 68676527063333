import React, {FC} from "react";

interface Props {
    fullscreen?: boolean
}

const Loading: FC<Props> = ({fullscreen=false}) => {
    return (
        <>
            {!fullscreen ? (
                    <span className="spinner-border spinner-border-lg align-middle ms-2" style={{
                        'position': 'absolute',
                        'top': '40%',
                        'left': '50%'
                    }} />
                ):
                (
                    <div className='swal2-container swal2-center swal2-backdrop-show'>
                        <span className="spinner-border spinner-border-lg align-middle ms-2" style={{
                            'position': 'absolute',
                            'top': '40%',
                            'left': '50%'
                        }} />
                    </div>
                )
            }
        </>
    );
}

export default Loading;