export default function BusinessBranchesReducer(state: any, action: any){
    switch (action.type) {
        case "setBusinessBranches":
            return {...state, business_branches: action.business_branches};
        case "setBusinessBranch":
            return {...state, business_branch: action.business_branch};
        case "setLoaded":
            return {...state, loaded: action.loaded};
        case "setLoading":
            return {...state, loadingUser: action.loadingUser};
        default:
            return state;
    }
}