import React, {Suspense, lazy, FC, useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../partials/FallbackView'
import {PageLink, PageTitle} from "../../_metronic/layout/core";
import {PageDataProviderContext} from "../components/PageDataProvider";
import {BusinessesContext} from "../providers/BusinessesContextProvider";
import {BusinessBranchesContext} from "../providers/BusinessBranchesContextProvider";
import {SaveChangesProvider} from "../providers/SaveChangesProvider";
import '../css/custom-panel.css';
import ResearchOverviewPage from "../modules/research/ResearchOverviewPage";
import CustomersPage from '../modules/customer/CustomersPage';
import ReservationsPage from '../modules/reservation/ReservationsPage';

type Props = {
}

const PrivateRoutes: FC<Props> = () => {

  const BusinessListPage = lazy(() => import('../modules/business/BusinessListPage'))
  const BusinessViewPage = lazy(() => import('../modules/business/BusinessEdit'))
  const UserListPage = lazy(() => import('../modules/master/components/Usuarios'))

  const BusinessBranchDashboardPage = lazy(() => import('../modules/business_branch/DashboardPage'))
  const BusinessBranchesPage = lazy(() => import('../modules/business_branch/BranchesPage'))
  const BusinessBranchAddPage = lazy(() => import('../modules/business_branch/BranchAddPage'))
  const BusinessBranchEditPage = lazy(() => import('../modules/business_branch/BranchEditPage'))

  const ResearchCommentPage = lazy(() => import('../modules/research/ResearchCommentPage'))
  const ResearchDashboardPage = lazy(() => import('../modules/research/ResearchOverviewPage'))

  const MenuPage = lazy(() => import('../modules/menu/MenuPage'))
  const MenuEditPage = lazy(() => import('../modules/menu/MenuEditPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <BusinessesContext.Consumer>
        {({business}) => (
            <BusinessBranchesContext.Consumer>
              {({business_branch}) => (
                  <PageDataProviderContext.Consumer>
                    {({updateToolbar}) => (
                        <Switch>
                          <Route path='/master/negocios'>
                            <BusinessListPage />
                          </Route>
                          <Route path='/master/negocio/:id' component={BusinessViewPage} />
                          <Route path='/master/usuarios' component={UserListPage} />

                          <Route path='/admin/dashboard'>
                            <BusinessBranchDashboardPage
                                updateToolbar={updateToolbar}
                                business={business}
                                business_branch={business_branch}
                            />
                          </Route>
                          <Route path='/admin/branches'>
                            <BusinessBranchesPage
                                updateToolbar={updateToolbar}
                                business={business}
                                business_branch={business_branch}
                            />
                          </Route>
                          <Route path='/admin/branch/:id/add'>
                            <BusinessBranchAddPage
                                updateToolbar={updateToolbar}
                                business={business}
                                business_branch={business_branch}
                            />
                          </Route>
                          <Route path='/admin/branch/:id/edit'>
                            <BusinessBranchEditPage
                                updateToolbar={updateToolbar}
                                business={business}
                                business_branch={business_branch}
                            />
                          </Route>

                          <Route path='/admin/menu/:id/edit'>
                            <SaveChangesProvider>
                              <MenuEditPage
                                  updateToolbar={updateToolbar}
                                  business={business}
                                  business_branch={business_branch}
                              />
                            </SaveChangesProvider>
                          </Route>

                          <Route path='/admin/menus' exact>
                            <MenuPage
                                updateToolbar={updateToolbar}
                                business={business}
                                business_branch={business_branch}
                            />
                          </Route>

                          <Route path='/admin/research/comment'>
                            <ResearchCommentPage
                                updateToolbar={updateToolbar}
                                business={business}
                                business_branch={business_branch}
                            />
                          </Route>
                          <Route path='/admin/research/dashboard'>
                            <ResearchOverviewPage
                                updateToolbar={updateToolbar}
                                business={business}
                                business_branch={business_branch}
                            />
                          </Route>
                          <Route path='/admin/customers'>
                            <CustomersPage
                                updateToolbar={updateToolbar}
                                business={business}
                                business_branch={business_branch}
                            />
                          </Route>

                          <Route path='/admin/reservations'>
                            <ReservationsPage
                                updateToolbar={updateToolbar}
                                business={business}
                                business_branch={business_branch}
                            />
                          </Route>

                          <Redirect from='/auth' to='/master/negocios' />
                          <Redirect exact from='/' to='/master/negocios' />
                          <Redirect exact from='/login' to='/auth/login' />
                          <Redirect to='/error/404' />
                        </Switch>
                    )}
                  </PageDataProviderContext.Consumer>
              )}
            </BusinessBranchesContext.Consumer>
        )}
      </BusinessesContext.Consumer>
    </Suspense>
  )
}

export {PrivateRoutes};
