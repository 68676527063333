import React, { FC, useContext, useEffect, useState } from "react";
import { PageProps } from "../../models/Page";
import { useAPI } from "../../hooks/useAPI";
import Loading from "../../components/Loading";
import ErrorLoading from "../../components/ErrorLoading";
import { useConfig } from "../../hooks/useConfig";
import clsx from "clsx";
import { PaginationReservations, ReservationAvailability, ReservationModel, ReservationStats } from "../../models/Reservation";
import ReservationAddModal from "./ReservationAddModal";
import { useHelper } from "../../hooks/useHelper";
import ReservationRow from "./ReservationRow";
import Success from "../../components/messages/Success";
import Confirm from "../../components/messages/Confirm";
import ConfirmModal from "./components/ConfirmModal";
import moment from "moment";

const ReservationsPage: FC<PageProps> = ({ updateToolbar, business_branch }) => {
    const [search, setSearch] = useState("");
    const [dateFrom, setDateFrom] = useState<string | undefined>(moment().format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState<string | undefined>(moment().add(6, 'days').format('YYYY-MM-DD'));
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        updateToolbar({
            title: "Gestão de Agendamentos",
            breadcrumbs: [
                {
                    title: business_branch.name,
                    path: '#',
                    isSeparator: false,
                    isActive: false,
                },
                {
                    title: '',
                    path: '',
                    isSeparator: true,
                    isActive: false,
                },
            ],
            buttons: []
        });
        loadReservations("", dateFrom, dateTo, status);
    }, []);

    const [loading, setLoading] = useState(true);
    const [loadingFull, setLoadingFull] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [reservations, setReservations] = useState<PaginationReservations | undefined>(undefined);
    const { api } = useAPI();
    const [page, setPage] = useState(1);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [showApproveConfirm, setShowApproveConfirm] = useState(false);
    const [showRefuseConfirm, setShowRefuseConfirm] = useState(false);
    const [showApproveSuccess, setShowApproveSuccess] = useState(false);
    const [showRefuseSuccess, setShowRefuseSuccess] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState<undefined | ReservationModel>(undefined);
    const [status, setStatus] = useState("");
    const [stats, setStats] = useState<undefined | ReservationStats>(undefined);
    const [availability, setAvailability] = useState<undefined | ReservationAvailability[]>(undefined);
    const [showAvailability, setShowAvailability] = useState<undefined | boolean>(true);

    const loadReservations = (search: string, dateFrom: string | undefined, dateTo: string | undefined, status: string) => {
        setLoading(true);
        setErrorLoading(false);
        api().get(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation?page=${page}&status=${status}&search=${search}&dateFrom=${dateFrom}&dateTo=${dateTo}`).then((resp) => {
            setLoading(false);
            setReservations(resp.data.result);
            setStats(resp.data.stats);
            setAvailability(resp.data.availability);
        }).catch(() => {
            setLoading(false);
            setErrorLoading(true);
        });
    }

    const getPages = (last_page: number): Array<number> => {
        let pages: Array<number> = [];
        for (let i = 0; i < last_page; i++) {
            pages.push(i + 1);
        }
        return pages;
    }

    useEffect(() => {
        loadReservations(search, dateFrom, dateTo, status);
    }, [page]);

    const clearAll = () => {
        setSearch("");
        setDateFrom(moment().format('YYYY-MM-DD'));
        setDateTo(moment().add(6, 'days').format('YYYY-MM-DD'));
        loadReservations("", moment().format('YYYY-MM-DD'), moment().add(6, 'days').format('YYYY-MM-DD'), "");
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            loadReservations(search, dateFrom, dateTo, status);
        }, 500);

        return () => clearTimeout(delayDebounceFn); // Cleanup the timeout if the component unmounts or query changes
    }, [search]);

    return (
        <>
            {showAddModal && (<ReservationAddModal
                onClose={() => { setShowAddModal(false); }}
                business_branch={business_branch}
                onSave={(reservation) => {
                    setShowAddModal(false);
                    loadReservations(search, dateFrom, dateTo, status);
                    setSaveSuccess(true);
                }}
                edit={false}
                show={showAddModal}
            />)}
            {errorLoading && (<ErrorLoading onTryAgain={() => {
                loadReservations(search, dateFrom, dateTo, status);
            }} />)}
            {saveSuccess && (
                <Success
                    message={"Agendamento adicionado com sucesso!"}
                    onClick={() => {
                        setSaveSuccess(false);
                    }}
                />
            )}

            {/********************************************
             * RESERVATION APPROVAL
             **/}
            {showApproveConfirm && selectedReservation && (
                <ConfirmModal
                    business_branch={business_branch}
                    reservation={selectedReservation}
                    onHide={() => {
                        setShowApproveConfirm(false);
                    }}
                    onSave={() => {
                        setShowApproveConfirm(false);
                        setShowApproveSuccess(true);
                        setSelectedReservation(undefined);
                        loadReservations(search, dateFrom, dateTo, status);
                    }}
                />
            )}
            {showApproveSuccess && (
                <Success
                    message={"Agendamento aprovado com sucesso!"}
                    onClick={() => {
                        setShowApproveSuccess(false);
                    }}
                />
            )}

            {/********************************************
             * RESERVATION REFUSE
             **/}
            {showRefuseConfirm && (
                <Confirm
                    message={'Deseja realmente recusar esse agendamento?'}
                    onConfirm={() => {
                        setShowRefuseConfirm(false);
                        setLoadingFull(true);
                        api()
                            .put(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation/${selectedReservation?.id}/empty/cancel-admin`, {})
                            .then((resp) => {
                                setLoadingFull(false);
                                if (resp.data.success) {
                                    setShowRefuseSuccess(true);
                                    loadReservations(search, dateFrom, dateTo, status);
                                } else {
                                    alert('Houve um erro, tente novamente.');
                                }
                            })
                            .catch(() => {
                                setLoadingFull(false);
                                alert('Houve um erro, tente novamente.')
                            });
                    }}
                    onClose={() => {
                        setShowRefuseConfirm(false);
                    }}
                />
            )}
            {showRefuseSuccess && (
                <Success
                    message={"Agendamento recusado com sucesso!"}
                    onClick={() => {
                        setShowRefuseSuccess(false);
                    }}
                />
            )}

            {loadingFull && (
                <Loading fullscreen={true} />
            )}
            {!errorLoading && (
                <div className="d-flex flex-column flex-lg-row">
                    <a href="#"
                        onClick={() => setShowAddModal(true)}
                        className="btn d-flex fixed-bottom me-5 mb-5 p-0 flex-center rounded-circle h-80px w-80px bg-primary z-3"
                        style={{right:'0px', left: 'auto'}}>
                        <i className="bi bi-plus-lg fs-1 text-white p-0"></i>
                    </a>
                    <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10" style={{
                        "position": (loading) ? "relative" : undefined
                    }}>
                        <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                            <div id="kt_app_toolbar_container" className="app-container d-flex flex-stack ">
                                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                                    <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                                        Agendamento
                                    </h1>
                                </div>
                                <div className="d-flex align-items-center gap-2 gap-lg-3">
                                    <div className="m-0">
                                        <a href="#" className="btn btn-sm btn-secondary fw-bold" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <i className="bi bi-funnel fs-5 p-0"></i></a>

                                        <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_66bb9a37c5d0a">
                                            <div className="px-7 py-5">
                                                <div className="fs-5 text-gray-900 fw-bold">Filtrar</div>
                                            </div>
                                            <div className="separator border-gray-200"></div>
                                            <div className="px-7 py-5">
                                                <div className="d-flex col-12 col-sm-12 flex-wrap mb-5">
                                                    <label className="form-label">De:</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="De"
                                                        value={dateFrom}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setDateFrom(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex col-12 col-sm-12 flex-wrap mb-5">
                                                    <label className="form-label">Até:</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Até"
                                                        value={dateTo}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setDateTo(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <button type="reset" className="btn btn-sm btn-danger me-2" data-kt-menu-dismiss="true" onClick={clearAll}>Limpar</button>

                                                    <button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true" onClick={() => {
                                                        if (dateFrom === "" || !moment(dateFrom).isValid() || dateTo === "" || !moment(dateTo).isValid()) {
                                                            alert('Preencha uma data válida!');
                                                            return;
                                                        }
                                                        loadReservations(search, dateFrom, dateTo, status);
                                                    }}>Aplicar</button>
                                                </div>
                                            </div>
                                        </div></div>
                                    {/*<a href="#" className="btn btn-sm btn-secondary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"><i className="bi bi-printer fs-5 p-0"></i></a>*/}
                                </div>
                            </div>
                        </div>
                        <div className="row g-7">
                            <div className="col-lg-4 col-12 ">
                                <div className="card card-flush bg-dark mb-3">
                                    <div className="card-body">
                                        <span className="text-gray-400 fs-7 fw-bold d-block">PERÍODO FILTRADO</span>
                                        <div className="text-white fs-3 fw-bold mb-5" style={{ textTransform: 'capitalize' }}>{moment(dateFrom).format('DD MMM')} - {moment(dateTo).format('DD MMM')}</div>
                                        <div className="text-gray-300 fw-semibold fs-3 mb-2">Reservas</div>
                                        {(loading) && (
                                            <div style={{
                                                "position": "relative",
                                                "minHeight": "100px"
                                            }}>
                                                <span className="spinner-border spinner-border-lg align-middle ms-2" style={{
                                                    'position': 'absolute',
                                                    'top': '40%',
                                                    'left': 'calc(50% - 18px)',
                                                    'color': '#fff'
                                                }} />
                                            </div>
                                        )}

                                        {(stats && !loading) && (
                                            <>
                                                <div className="d-flex flex-stack">
                                                    <div className="d-flex flex-column content-justify-center flex-row-fluid">
                                                        <div className="d-flex fw-semibold align-items-center">
                                                            <div className="bullet w-8px h-8px rounded-2 bg-success me-3"></div>
                                                            <div className="text-gray-500 flex-grow-1 me-4">Confirmadas</div>
                                                            <div className="fw-bolder text-white fs-3">{stats.total_confirmed}</div>
                                                        </div>
                                                        <div className="d-flex fw-semibold align-items-center my-3">
                                                            <div className="bullet w-8px h-8px rounded-2 bg-warning me-3"></div>
                                                            <div className="text-gray-500 flex-grow-1 me-4">Pendentes</div>
                                                            <div className="fw-bolder text-gray-700 text-xxl-end">{stats.total_waiting}</div>
                                                        </div>
                                                        <div className="d-flex fw-semibold align-items-center">
                                                            <div className="bullet w-8px h-8px rounded-2 me-3 bg-danger"></div>
                                                            <div className="text-gray-500 flex-grow-1 me-4">Recusadas</div>
                                                            <div className="fw-bolder text-gray-700 text-xxl-end">{stats.total_cancelled}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed my-5 border-gray-700"></div>
                                                <div className="d-flex flex-stack mb-2">
                                                    <div className="text-gray-300 fw-semibold fs-3 me-2">Pessoas</div>
                                                    <div className="d-flex align-items-senter">
                                                        <span className="text-gray-300 fw-bolder fs-3">{stats.total_people}</span>
                                                    </div>
                                                </div>
                                                {/*<div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                    <span className="fs-6 text-gray-500">Disponibilidade</span>
                                                    <span className="fw-bold fs-6 text-gray-500">{stats.restaurant_seats - stats.total_people} <i className="bi bi-person text-muted"></i></span>
                                                </div>
                                                <div className="progress" role="progressbar" aria-label="Warning example">
                                                    <div className="progress-bar text-bg-warning" style={{ width: `${stats.occupation}%`, 'minWidth': '10%' }}>{Math.round(stats.occupation)}%</div>
                                                </div>*/}
                                            </>
                                        )}
                                    </div>
                                </div>
                                {availability && (
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-stack fs-4">
                                                <a
                                                    onClick={() => {
                                                        setShowAvailability(!showAvailability);
                                                    }}
                                                    href="#" className="fw-bold rotate collapsible collapsed d-flex flex-stack w-100">
                                                    <span className="text-gray-700 fs-7 fw-bold d-block">DISPONIBILIDADES</span>
                                                    <span className="ms-2 rotate-180"><i className="bi bi-plus-lg fs-4 text-primary"></i></span>
                                                </a>
                                            </div>
                                            <div id="kt_customer_view_details" className={clsx('', {
                                                'collapse show': showAvailability === true,
                                                'collapse': showAvailability === false,
                                                'collapsing': showAvailability === undefined
                                            })}>
                                                <div className="pt-5 fs-6">
                                                    {availability[0] && (
                                                        <div className="col-12">
                                                            <div className="d-flex justify-content-between align-items-center w-100 mt-auto mb-2">
                                                                <span className="fs-6 text-gray-700" style={{ textTransform: 'capitalize' }}><strong>{moment(availability[0].date).format("dddd")}</strong>, {moment(availability[0].date).format("DD MMM")} </span>
                                                                <div>
                                                                    <span className="fs-6 text-gray-700">{availability[0].total_confirmed}</span>
                                                                    <div className="bullet w-8px h-8px rounded-2 bg-success ms-1"></div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                                <div className="w-50">
                                                                    <div className="progress" role="progressbar" style={{ height: '20px' }}>
                                                                        <div className="progress-bar" style={{ width: `${availability[0].occupation}%`, 'minWidth': '10%' }}>{Math.round(availability[0].occupation)}%</div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-50 text-end">
                                                                    <i className="bi bi-person text-muted me-1 fs-3"></i>
                                                                    <span className="fw-bolder text-gray-700 fs-3">{availability[0].total_people}</span>
                                                                    <span className="fs-6 text-gray-700"> de {availability[0].restaurant_seats}</span>
                                                                </div>
                                                            </div>
                                                            <div className="separator separator-dashed my-5"></div>
                                                        </div>
                                                    )}
                                                    {availability.map((item, index) => {
                                                        if (index > 0) {
                                                            return (
                                                                <div className="d-block">
                                                                    <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                        <span className="fs-6 text-gray-700" style={{ textTransform: 'capitalize' }}>{moment(item.date).format("dddd")}, {moment(item.date).format("DD MMM")}</span>
                                                                        <div>
                                                                            <span className="fs-6 text-gray-700">{item.total_confirmed}</span>
                                                                            <div className="bullet w-8px h-8px rounded-2 bg-success ms-1"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                                        <div className="w-50">
                                                                            <div className="progress" role="progressbar">
                                                                                <div className="progress-bar text-bg-warning" style={{ width: `${item.occupation}%`, 'minWidth': '10%' }}>{Math.round(item.occupation)}%</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-50 text-end">
                                                                            <i className="bi bi-person text-muted me-1 fs-3"></i>
                                                                            <span className="fs-6 text-gray-700"><strong>{item.total_people}</strong> de {item.restaurant_seats}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="separator separator-dashed my-5"></div>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-lg-8 col-12">
                                <div className="card card-flush">
                                    <div className="card-header p-6">
                                        <div className="col-lg-8 col-12 mb-2">
                                            <div className="input-group pe-lg-5">
                                                <span className="input-group-text border-0" id="basic-addon1"><i className="bi bi-search fs-3"></i></span>
                                                <input
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setSearch(e.target.value);
                                                    }}
                                                    type="text" className="form-control form-control-lg form-control-solid" placeholder="Nome ou telefone" aria-label="Username" aria-describedby="basic-addon1" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12 mb-2" data-select2-id="select2-data-6-webc">
                                            <select
                                                className="form-select form-select-solid form-select-lg select2-hidden-accessible"
                                                onChange={(e) => {
                                                    loadReservations(search, dateFrom, dateTo, e.target.value);
                                                    setStatus(e.target.value);
                                                }}>
                                                <option value="">Todos</option>
                                                <option value="CON" className="text-primary" data-select2-id="select2-data-9-teyi">Confirmado</option>
                                                <option value="WAI" data-select2-id="select2-data-10-42ur">Pendente</option>
                                                <option value="REC" data-select2-id="select2-data-11-mnpf">Recusada</option>
                                                <option value="CAN" data-select2-id="select2-data-11-mnpf">Cancelada</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="card-body px-6 py-0">
                                        <div className="separator border-gray-300"></div>
                                        <table className="table table-row-dashed table-row-gray-300 align-top fs-6 gy-5 my-0">
                                            <tbody>
                                                {loading && (
                                                    <tr>
                                                        <td colSpan={1}>
                                                            {loading && (
                                                                <div style={{
                                                                    "position": "relative",
                                                                    "minHeight": "100px"
                                                                }}>
                                                                    <Loading />
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}

                                                {!loading && (
                                                    <>
                                                        {reservations?.data.map((item) => (
                                                            <ReservationRow
                                                                reservation={item} onClick={(customer) => {
                                                                }}
                                                                business_branch={business_branch}
                                                                key={`${item.id}`}
                                                                onApprove={(reservation) => {
                                                                    setSelectedReservation(reservation);
                                                                    setShowApproveConfirm(true);
                                                                }}
                                                                onRefuse={(reservation) => {
                                                                    setSelectedReservation(reservation);
                                                                    setShowRefuseConfirm(true);
                                                                }}
                                                            />
                                                        ))}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {!loading && (
                                    <div className="d-flex flex-stack flex-wrap pt-10">
                                        <div className="fs-6 fw-bold text-gray-700">Mostrando {reservations?.from} a {reservations?.to} de {reservations?.total}</div>
                                        <ul className="pagination">
                                            {page > 1 && (
                                                <li className="page-item previous">
                                                    <a
                                                        href=""
                                                        className="page-link"
                                                        onClick={(e) => {
                                                            setPage(page - 1);
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        <i className="previous"></i>
                                                    </a>
                                                </li>
                                            )}
                                            {reservations && getPages(reservations?.last_page).map((i) => {
                                                return (
                                                    <li className={clsx("page-item", {
                                                        "active": i === reservations?.current_page
                                                    })}>
                                                        <a
                                                            href=""
                                                            className="page-link"
                                                            onClick={(e) => {
                                                                setPage(i);
                                                                e.preventDefault();
                                                            }}
                                                        >{i}</a>
                                                    </li>
                                                );
                                            })}
                                            {reservations && page < reservations?.last_page && (
                                                <li className="page-item next">
                                                    <a
                                                        href=""
                                                        className="page-link"
                                                        onClick={(e) => {
                                                            setPage(page + 1);
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        <i className="next"></i>
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ReservationsPage;