import React, {FC, useEffect, useState} from 'react'
import {Link, useLocation} from "react-router-dom";
import {useBreadCrumb} from "../../../hooks/useBreadCrumb";

const Header: FC = () => {
    const [breadcrumb, setBreadcrumb] = useState(<div></div>);
    let location = useLocation();
    const {getBreadCrumbObject} = useBreadCrumb();

    useEffect(() => {
        if (location.pathname === "/master/usuarios") {
            setBreadcrumb(usuarioBreadcrumb());
        } else if (location.pathname === "/master/negocios") {
            setBreadcrumb(businessBreadcrumb());
        } else if (location.pathname.indexOf("/master/negocio/") !== -1) {
            // TODO: Work on this to avoid this setInterval
            let interval = setInterval(() => {
                try {
                    const pathname = location.pathname.split('/');
                    const id = pathname[pathname.length - 1];
                    // @ts-ignore
                    if (getBreadCrumbObject().id.toString() === id.toString()) {
                        clearInterval(interval);
                        setBreadcrumb(businessShowBreadcrumb());
                    }
                } catch (e) {
                }
            }, 100);
        } else {
            return setBreadcrumb(<ul></ul>);
        }
    }, [location.pathname]);

    function usuarioBreadcrumb() {
        return (
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1 text-gray-400">
                <li className="breadcrumb-item text-gray-700">Usuários</li>
            </ul>
        );
    }

    function businessBreadcrumb() {
        return (
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1 text-gray-400">
                <li className="breadcrumb-item text-gray-700">Negócios</li>
            </ul>
        );
    }

    function businessShowBreadcrumb() {
        return (
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1 text-gray-400">
                <li className="breadcrumb-item">
                    <Link to="/master/negocios" className="btn-color-muted btn-active-color-primary">Negócios</Link>
                </li>
                <li className="breadcrumb-item">
                    <span className="bullet bg-gray-300 w-5px h-2px"></span>
                </li>
                <li className="breadcrumb-item">
                    <Link to={`/master/negocio/${getBreadCrumbObject().id}`}
                          className="btn-color-muted btn-active-color-primary">{getBreadCrumbObject().name}</Link>
                </li>
                <li className="breadcrumb-item">
                    <span className="bullet bg-gray-300 w-5px h-2px"></span>
                </li>
                <li className="breadcrumb-item text-gray-700">Dados do Negócio</li>
            </ul>
        );
    }

    return (
        <div
            className='header-menu align-items-stretch'
            data-kt-drawer='true'
            data-kt-drawer-name='header-menu'
            data-kt-drawer-activate='{default: true, lg: false}'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction='end'
            data-kt-drawer-toggle='#kt_header_menu_mobile_toggle'
            data-kt-swapper='true'
            data-kt-swapper-mode='prepend'
            data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
        >
            <div className="d-flex align-items-stretch" id="kt_header_nav">
                <div data-kt-swapper="true"
                     className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                    {breadcrumb}
                </div>
            </div>
        </div>
    )
}

export {Header}
