import React from "react";
import {useHistory} from "react-router-dom";

const BackButton = () => {
    let history = useHistory();
    return (
        <a href="#" className='btn btn-sm btn-light me-3' onClick={(e) => {
            e.preventDefault();
            history.goBack();
        }}>Voltar</a>
    );
}

export default BackButton;