import React, { useEffect, useState } from "react";

// Custom hooks
import { useAuth } from "../../hooks/useAuth";
import { useAPI } from "../../hooks/useAPI";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomerModel } from "../../models/CustomerModel";
import clsx from "clsx";
import InputMask from "react-input-mask";
import Error from "../../components/messages/Error";
import { useCustomer } from "../../hooks/useCustomer";

const CommentSchema = Yup.object().shape({
    name: Yup.string()
        .required('Nome é obrigatório.'),
    phone: Yup.string()
        .required('Telefone é obrigatório.'),
    email: Yup.string()
        .email('Email inválido.')
        .required('Email é obrigatório.'),
    birth_date: Yup.string()
        .required('Data de nascimento é obrigatório.'),
    customer_id: Yup.number()
});

function CustomerAddModal(props: any) {
    const [visible, setVisible] = useState(true);
    const { getProfiles, hasAbility, PROFILE_EDITOR } = useAuth();
    const { api } = useAPI();
    const customerApi = useCustomer();

    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [profile, setProfile] = useState(hasAbility("user-profile") ? "" : PROFILE_EDITOR);
    const [senha, setSenha] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [customer, setCustomer] = useState<CustomerModel | undefined>(undefined);

    // Initialize state if is on editing mode
    useEffect(() => {
        if (props.edit) {
            setNome(props.user.name);
            setEmail(props.user.email);
            setProfile(props.user.profile_id);
        }
    }, []);

    // Close modal
    const closeModal = () => {
        setVisible(false);
        props.onClose();
    };

    // Save user. Returns a Promise
    const saveUser = () => {
        const createUser = () => {
            return api()
            .post(`/api/business/${props.business_branch.business_id}/branch/${props.business_branch.id}/customer-create`, formik.values);
        }

        const updateUser = () => {
            return api().put(`/api/user/${props.user.id}`, {
                'name': nome,
                'email': email,
                'password': senha,
                'profile_id': profile
            });
        }

        if (props.edit) {
            return updateUser();
        } else {
            return createUser();
        }
    }

    const formik = useFormik({
        initialValues: {
            name: (customer) ? customer.name : "",
            email: (customer) ? customer.email : "",
            phone: (customer) ? customer.phone : "",
            birth_date: (customer) ? customer.birth_date : "",
            customer_id: undefined
        },
        validationSchema: CommentSchema,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values: any, { setStatus, setSubmitting }) => {
            if (showLoading) { return; }

            setShowLoading(true);
            saveUser().then((resp) => {
                setShowLoading(false);

                if(resp) {
                    if (resp.data.success) {
                        props.onSaveUser();
                        closeModal();
                    } else {
                        setVisible(false);
                        setErrorMessage(resp.data.msg);
                        setShowError(true);
                    }
                }


            }, (err) => {
                setShowLoading(false);
                setShowError(true);
                setErrorMessage("Tivemos um problema ao salvar. Por favor, tente novamente.");
            });
        },
    });

    return (
        <div>
            {(showError) ? <Error
                message={errorMessage}
                onClick={(e: any) => {
                    setShowError(false);
                    setVisible(true);
                }}
            /> : ''}
            <div className={"modal fade" + ((visible) ? ' show' : '')}>
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                        <div className="modal-header" id="kt_modal_add_user_header">
                            <h2 className="fw-bolder">{(props.edit) ? 'Editar' : 'Adicionar Novo'} Cliente</h2>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={closeModal}
                            >
                                <span className="svg-icon svg-icon-2x">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16"
                                            height="2" rx="1"
                                            transform="rotate(-45 6 17.3137)"
                                            fill="black"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2"
                                            rx="1" transform="rotate(45 7.41422 6)"
                                            fill="black"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                            <form onSubmit={formik.handleSubmit} noValidate={true}>
                                <div className="d-flex flex-column scroll-y me-n7 pe-7"
                                    id="kt_modal_add_user_scroll" data-kt-scroll="true"
                                >
                                    <div className={"alert alert-danger" + ((showError) ? "" : " d-none")}>
                                        <div className="d-flex flex-column">
                                            <h4 className="mb-1 text-dark">Erro</h4>
                                            <span>{errorMessage}</span>
                                        </div>
                                    </div>
                                    <div className="fv-row mb-7">
                                        <label
                                            className="required fw-bold fs-6 mb-2">Nome</label>
                                        <input type="text"
                                            className={clsx(
                                                "form-control form-control-solid mb-3 mb-lg-0",
                                                { 'is-invalid': formik.touched.name && formik.errors.name },
                                                {
                                                    'is-valid': formik.touched.name && !formik.errors.name,
                                                }
                                            )}
                                            required={true}
                                            {...formik.getFieldProps('name')}
                                            placeholder="Nome" />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <span role='alert'>{formik.errors.name}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="fv-row mb-7">
                                        <label
                                            className="required fw-bold fs-6 mb-2">Email</label>
                                        <input type="email"
                                            placeholder="Email"
                                            maxLength={255}
                                            {...formik.getFieldProps('email')}
                                            className={clsx(
                                                "form-control form-control-solid mb-3 mb-lg-0",
                                                { 'is-invalid': formik.touched.email && formik.errors.email },
                                                {
                                                    'is-valid': formik.touched.email && !formik.errors.email,
                                                }
                                            )}
                                        />
                                        {formik.touched.email && formik.errors.email && (
                                            <div className='fv-plugins-message-container'>
                                                <span role='alert'>{formik.errors.email}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="fv-row mb-7">
                                        <label
                                            className="required fw-bold fs-6 mb-2">Telefone</label>
                                        <InputMask
                                            mask={'(99) 99999-9999'}
                                            type="text"
                                            placeholder="Telefone"
                                            {...formik.getFieldProps('phone')}
                                            className={clsx(
                                                "form-control form-control-solid mb-3 mb-lg-0",
                                                { 'is-invalid': formik.touched.phone && formik.errors.phone },
                                                {
                                                    'is-valid': formik.touched.phone && !formik.errors.phone,
                                                }
                                            )}
                                        />
                                        {formik.touched.phone && formik.errors.phone && (
                                            <div className='fv-plugins-message-container'>
                                                <span role='alert'>{formik.errors.phone}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="fv-row mb-7">
                                        <label
                                            className="required fw-bold fs-6 mb-2">Data de Nascimento</label>
                                        <InputMask
                                            mask={'99/99/9999'}
                                            type="text"
                                            placeholder="Data de nascimento"
                                            {...formik.getFieldProps('birth_date')}
                                            className={clsx(
                                                "form-control form-control-solid mb-3 mb-lg-0",
                                                { 'is-invalid': formik.touched.birth_date && formik.errors.birth_date },
                                                {
                                                    'is-valid': formik.touched.birth_date && !formik.errors.birth_date,
                                                }
                                            )}
                                        />
                                        {formik.touched.birth_date && formik.errors.birth_date && (
                                            <div className='fv-plugins-message-container'>
                                                <span role='alert'>{formik.errors.birth_date}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="text-center pt-15">
                                    <button type="reset" className="btn btn-light me-3"
                                        data-kt-users-modal-action="cancel"
                                        onClick={closeModal}
                                    >Cancelar
                                    </button>
                                    <button type="submit" className="btn btn-primary"
                                        data-kt-indicator={(showLoading) ? "on" : "off"}
                                    >
                                        <span className="indicator-label">Salvar</span>
                                        <span className="indicator-progress">Salvando...
                                            <span
                                                className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerAddModal;