import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTSVG} from '../../../../_metronic/helpers'
import {OverlayTrigger, Tooltip} from "react-bootstrap-v5";

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  disabled?: boolean
    tooltip?: string
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
    disabled=false,
    tooltip,
}) => {
  const {pathname} = useLocation()
    const htmlTitle = (<span className='menu-title'>{title}</span>);

  return (
    <div className='menu-item me-lg-1'>
      <Link
        className={clsx('menu-link py-3', {
          active: checkIsActive(pathname, to),
          'text-muted': disabled
        })}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}

        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
        )}

          {(tooltip !== undefined) ?
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                      <Tooltip id={`tolltip-to${to.replace('/', '-')}`}>
                          {tooltip}
                      </Tooltip>
                  }>
                  {htmlTitle}
              </OverlayTrigger>
              :
              htmlTitle
          }

        {hasArrow && <span className='menu-arrow'></span>}
      </Link>
    </div>
  )
}

export {MenuItem}
