import clsx from 'clsx'
import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {HeaderUserMenu} from './HeaderUserMenu'
import {useLayout} from '../../../../_metronic/layout/core'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const TopbarAdmin: FC = () => {
  const {config} = useLayout()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
          <div className="d-flex align-items-center ms-1 ms-lg-3 d-none d-lg-block">
              {/*begin::Menu- wrapper*/}
              <div className="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px"
                   data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                  {/*begin::Svg Icon | path: icons/duotune/general/gen022.svg*/}
                  <span className="svg-icon svg-icon-1 text-gray-500">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                         fill="currentColor" className="bi bi-bell" viewBox="0 0 16 16">
														<path
                                                            d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
													  </svg>
												</span>
                  {/*end::Svg Icon*/}
              </div>
              {/*begin::Menu*/}
              <div className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px" data-kt-menu="true">
                  {/*begin::Heading*/}
                  <div className="d-flex flex-column bgi-no-repeat rounded-top"
                       style={{
                           'backgroundImage':`url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`
                       }}>
                      {/*begin::Title*/}
                      <h3 className="text-white fw-bold px-9 mt-10 mb-6">Notificações</h3>
                      {/*end::Title*/}
                  </div>
                  {/*end::Heading*/}
                  {/*begin::Post*/}
                  <div className="scroll-y mh-325px my-5 px-8" id="kt_post">
                      {/*begin::Item*/}
                      <div className="flex-stack py-4">
                          {/*begin::Title*/}
                          <h3 className="text-dark fw-bolder">Aqui você irá receber todas as novidades da nossa
                              ferramenta</h3>
                          {/*end::Title*/}
                          {/*begin::Text*/}
                          <p className="text-gray-600 fw-bold pt-1">Sempre que nosso time efetuar uma atualização, nós
                              vamos notificar por aqui.</p>
                          {/*end::Text*/}
                      </div>
                      {/*end::Item*/}
                      <div className="separator"></div>
                      {/*begin::Central de Ajuda*/}
                      <div className="flex-stack py-4">
                          {/*begin::Title*/}
                          <h4 className="fs-1x fw-bolder mb-3">Dúvida?</h4>
                          {/*end::Title*/}
                          {/*begin::Text*/}
                          <a href="#" className=".btn-link">
															<span className="pe-3">
																<svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                     height="16" fill="currentColor"
                                                                     className="bi bi-arrow-up-right-square"
                                                                     viewBox="0 0 16 16">
																	<path fill-rule="evenodd"
                                                                          d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"></path>
																</svg>
															</span>Acesse nossa central de ajuda</a>
                          {/*end::Text*/}
                      </div>
                      {/*end::Central de Ajuda*/}
                  </div>
                  {/*end::Post*/}
                  {/*begin::Tab content*/}
                  <div className="tab-content">
                      {/*begin::Tab panel*/}
                      <div className="tab-pane fade show active" id="kt_topbar_notifications_2" role="tabpanel">
                          {/*begin::Wrapper*/}

                          {/*end::Wrapper*/}
                      </div>
                      {/*end::Tab panel*/}
                  </div>
                  {/*end::Tab content*/}
              </div>
              {/*end::Menu*/}
              {/*end::Menu wrapper*/}
          </div>
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol symbol-circle ms-1 ms-lg-3', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='metronic' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  )
}

export {TopbarAdmin}
