export default function BusinessesReducer(state: any, action: any){
    switch (action.type) {
        case "setBusiness":
            return {...state, business: action.business};
        case "setBusinesses":
            return {...state, businesses: action.businesses};
        case "setLoading":
            return {...state, loading: action.loading};
        default:
            return state;
    }
}