import React, {FC, useEffect, useState} from "react";
import {BusinessBranchModel, BusinessModel} from "../../../models/BusinessModel";
import {useAuth} from "../../../hooks/useAuth";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";

interface Props {
    businesses: Array<BusinessModel>
    business?: BusinessModel
    onChange: (business: BusinessModel) => void
}

const BusinessesMenu: FC<Props> = ({children, businesses, onChange, business}) => {
    const {getBusinesses, getBusiness} = useAuth();
    const auth = useAuth();

    return (
        <div className="menu-item">
            <div className="menu-content">
                {/*begin::Trigger*/}
                <button type="button" className="btn btn-link btn-color-gray-700" data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-start"
                        data-kt-menu="true"
                >
                    <div className="symbol-circle symbol symbol-30px pe-2">
                        <img
                            src={(business?.avatar_url !== null) ? business?.avatar_url : toAbsoluteUrl('/media/imgs/cardapio-item-nopicture.jpg')}
                            alt=""/>
                    </div>
                    <span className="d-inline-block text-truncate" style={{
                        'maxWidth': '120px',
                        'position': 'relative',
                        'top': '7px'
                    }}>{(business !== undefined) ? business.name : ''}</span>
                    <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                 fill="currentColor" className="bi bi-arrow-down-short"
                                 viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/>
                              </svg>
                        </span>
                </button>
                {/*end::Trigger*/}
                {/*begin::Menu*/}
                <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                    data-kt-menu="true">
                    {typeof businesses !== "boolean" ?
                        businesses?.map((item, index) => {
                            return (
                                <div className="menu-item px-3" key={index}>
                                    <a href="" className="menu-link px-3" onClick={(e) => {
                                        e.preventDefault();
                                        onChange(item);
                                    }}>
                                        {item.name}
                                    </a>
                                </div>
                            );
                        })
                        : ''
                    }
                </div>
                {/*end::Menu Estabelecimentos*/}
            </div>
        </div>
    );
}

export default BusinessesMenu;