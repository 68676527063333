import React, {FC, useEffect, useReducer} from "react";
import UserReducer from "../reducers/UserReducer";
import {BusinessBranchModel, BusinessModel} from "../models/BusinessModel";
import BusinessBranchesReducer from "../reducers/BusinessBranchesReducer";
import {AddressModel} from "../models/Address";
import {ScheduleModel} from "../models/Schedule";
import {UserModel} from "../models/UserModel";
import {LinkModel} from "../models/Links";
import {DefaultBusinessBranch} from "./DefaultData";

interface DataProps {
    business_branches: Array<BusinessBranchModel>
    business_branch: BusinessBranchModel
    storedBusinessBranchId: string | null
    loaded: boolean
    loading: boolean
    loadBusinessesBranch: () => Promise<boolean>
    setBusinessBranch: (business_branch: BusinessBranchModel) => void
}
const DefaultData: DataProps = {
    business_branches: [],
    business_branch: DefaultBusinessBranch,
    storedBusinessBranchId: window.localStorage.getItem('business_branch_id'),
    loaded: false,
    loading: false,
    loadBusinessesBranch: () => {return new Promise<boolean>(resolve => resolve(true))},
    setBusinessBranch: (business_branch: BusinessBranchModel) => {}
};
const BusinessBranchesContext = React.createContext(DefaultData);
interface Props {
    business_branches?: Array<BusinessBranchModel>
}
const BusinessBranchesContextProvider: FC<Props> = ({children, business_branches}) => {
    const loadBusinessesBranch = async () => {
        dispatch({type: 'setLoading', loading: true});
        return new Promise((resolve) => {
            resolve(false);
            alert("BusinessBranchContextProvider.loadBusinessBranch function incomplete");
        });
    }
    const getBusinessBranchById = (business_branches: Array<BusinessBranchModel> | undefined, business_branch_id: string | null) => {
        if(business_branches === undefined) {return undefined}
        for (let i in business_branches) {
            if(business_branches[i].id.toString() === business_branch_id) {
                return business_branches[i];
            }
        }

        return undefined;
    }
    const setBusinessBranch = (business_branch: BusinessBranchModel) => {
        dispatch({type: 'setBusinessBranch', business_branch: business_branch});
        window.localStorage.setItem('business_branch_id', (business_branch !== undefined) ? business_branch.id.toString() : "-1");
    }

    const business_branch = getBusinessBranchById(business_branches, window.localStorage.getItem('business_branch_id'));
    const [data, dispatch] = useReducer(BusinessBranchesReducer, {
        ...DefaultData,
        business_branches: business_branches,
        business_branch: (business_branch !== undefined) ? business_branch : ((business_branches !== undefined) ? business_branches[0] : undefined),
        setBusinessBranch: setBusinessBranch
    });

    useEffect(() => {
        if(business_branches !== undefined){
            dispatch({type: 'setBusinessBranches', business_branches: business_branches});
            setBusinessBranch((business_branch !== undefined) ? business_branch : business_branches[0]);
        }

    }, [business_branches])


    return (<BusinessBranchesContext.Provider value={data}>{children}</BusinessBranchesContext.Provider>);
}

export {BusinessBranchesContextProvider, BusinessBranchesContext};