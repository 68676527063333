import {toAbsoluteUrl} from '../../_metronic/helpers'
import {useEffect} from "react";

export function FallbackView() {

    return (
        <div className='splash-screen'>
            <img src={toAbsoluteUrl('/media/logos/logo-1.svg')} alt="Metronic logo" className="h-30px"/>
            <span>Carregando ...</span>
        </div>
    )
}
