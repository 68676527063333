/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react'
import { checkIsActive, KTSVG } from '../../../../_metronic/helpers'
import { AsideMenuItem } from './AsideMenuItem'
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from "../../../hooks/useAuth";
import BusinessBranchesMenu from "./BusinessBranchesMenu";
import { BusinessModel } from "../../../models/BusinessModel";
import BusinessesMenu from "./BusinessesMenu";
import { BusinessesDataContext } from "../../../providers/BusinessContextProvider";
import { BusinessesContext } from "../../../providers/BusinessesContextProvider";
import { BusinessBranchesContext } from "../../../providers/BusinessBranchesContextProvider";
import { useLocation } from "react-router";
import clsx from "clsx";
import { AsideMenuItemWithSub } from "../../../../_metronic/layout/components/aside/AsideMenuItemWithSub";


export function AsideMenuMainAdmin() {
    const history = useHistory();
    const { pathname } = useLocation();

    return (
        <>
            <BusinessesContext.Consumer>
                {({ businesses, business, setBusiness, loading }) => (
                    <BusinessBranchesContext.Consumer>
                        {({ business_branch, business_branches }) => (
                            <>
                                <BusinessesMenu onChange={(business) => {
                                    setBusiness(business);
                                    window.location.href = "/admin/dashboard";
                                }} business={business} businesses={businesses} />
                                <BusinessBranchesMenu business_branches={business_branches}
                                    business_branch={business_branch} />
                                <div className='menu-item'>
                                    <div className='menu-content pb-2'>
                                        <span
                                            className='menu-section text-muted text-uppercase fs-8 ls-1'>NAVEGUE POR AQUI</span>
                                    </div>
                                </div>
                                <AsideMenuItem
                                    to="/admin/dashboard"
                                    title='Página Inicial'
                                    icon='/media/icons/duotune/custom/bi-house.svg'
                                />
                                <AsideMenuItem
                                    to={`/admin/branch/${(business_branch !== undefined) ? business_branch.id : ''}/edit`}
                                    title='Perfil'
                                    icon='/media/icons/duotune/custom/bi-shop-window.svg'
                                />
                            </>
                        )}
                    </BusinessBranchesContext.Consumer>
                )}
            </BusinessesContext.Consumer>


            <AsideMenuItem
                to="/admin/menus"
                title='Menu Digital'
                icon='/media/icons/duotune/custom/bi-book.svg'
            />
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                <span className="menu-link">
                    <span className="menu-icon">
                        <span className="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                fill="currentColor" className="bi bi-journal-check"
                                viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
                                <path
                                    d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"></path>
                                <path
                                    d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"></path>
                            </svg>
                        </span>
                    </span>
                    <span className={clsx("menu-title", {

                    })}>Pesquisa</span>
                    <span className="menu-arrow" />
                </span>
                <div className="menu-sub menu-sub-accordion menu-active-bg">
                    <div className="menu-item">
                        <Link to="/admin/research/dashboard" className={clsx("menu-link", {
                            "active": checkIsActive(pathname, "/admin/research/dashboard")
                        })}>
                            <span className="menu-bullet">
                                <span className="bullet bullet-dot" />
                            </span>
                            <span className="menu-title">Visão Geral</span>
                        </Link>
                    </div>
                    <div className="menu-item">
                        <Link to="/admin/research/comment" className={clsx("menu-link", {
                            "active": checkIsActive(pathname, "/admin/research/comment")
                        })}>
                            <span className="menu-bullet">
                                <span className="bullet bullet-dot" />
                            </span>
                            <span className="menu-title">Avaliações</span>
                        </Link>
                    </div>
                </div>
            </div>
            <AsideMenuItem
                to={`/admin/customers`}
                title='Clientes'
                icon='/media/icons/duotune/custom/bi-people.svg'
            />
            <AsideMenuItem
                to="#"
                title='Delivery'
                icon='/media/icons/duotune/custom/bi-delivery.svg'
                disabled={true}
                tooltip={'Em breve'}
            />
            <AsideMenuItem
                to="#"
                title='Agendamento'
                icon='/media/icons/duotune/custom/bi-schedule.svg'
                disabled={true}
                tooltip={'Em breve'}
            />
            

            <AsideMenuItem
                to="#"
                title='Comunicação'
                icon='/media/icons/duotune/custom/bi-megaphone.svg'
                disabled={true}
                tooltip={'Em breve'}
            />
            <AsideMenuItem
                to="#"
                title='Configurações'
                icon='/media/icons/duotune/custom/bi-gear.svg'
                disabled={true}
            />
            <div className="menu-item">
                <div className="menu-content">
                    <div className="separator mx-1 my-4"></div>
                </div>
            </div>
            <div className="menu-item">
                <a className="menu-link" href="#">
                    <span className="menu-icon">
                        {/*begin::Svg Icon | path: icons/duotune/coding/cod003.svg*/}
                        <span className="svg-icon svg-icon-2 text-gray-500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M16.95 18.9688C16.75 18.9688 16.55 18.8688 16.35 18.7688C15.85 18.4688 15.75 17.8688 16.05 17.3688L19.65 11.9688L16.05 6.56876C15.75 6.06876 15.85 5.46873 16.35 5.16873C16.85 4.86873 17.45 4.96878 17.75 5.46878L21.75 11.4688C21.95 11.7688 21.95 12.2688 21.75 12.5688L17.75 18.5688C17.55 18.7688 17.25 18.9688 16.95 18.9688ZM7.55001 18.7688C8.05001 18.4688 8.15 17.8688 7.85 17.3688L4.25001 11.9688L7.85 6.56876C8.15 6.06876 8.05001 5.46873 7.55001 5.16873C7.05001 4.86873 6.45 4.96878 6.15 5.46878L2.15 11.4688C1.95 11.7688 1.95 12.2688 2.15 12.5688L6.15 18.5688C6.35 18.8688 6.65 18.9688 6.95 18.9688C7.15 18.9688 7.35001 18.8688 7.55001 18.7688Z"
                                    fill="black" />
                                <path opacity="0.3"
                                    d="M10.45 18.9687C10.35 18.9687 10.25 18.9687 10.25 18.9687C9.75 18.8687 9.35 18.2688 9.55 17.7688L12.55 5.76878C12.65 5.26878 13.25 4.8687 13.75 5.0687C14.25 5.1687 14.65 5.76878 14.45 6.26878L11.45 18.2688C11.35 18.6688 10.85 18.9687 10.45 18.9687Z"
                                    fill="black" />
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title">nexp digital v0.1</span>
                </a>
            </div>
        </>
    )
}
