/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useHistory} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from "../../../hooks/useAuth";

const HeaderUserMenu: FC = () => {
  let history = useHistory();
  const {getUser, getProfile, deleteToken} = useAuth();
  const user: UserModel = getUser();

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.name}
            </div>
            <span className='fw-bold text-muted fs-7'>
              {getProfile(user.profile_id).name}
            </span>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <a onClick={(e) => {
          e.preventDefault();
          deleteToken();
          history.push('/auth/login');
        }} className='menu-link px-5'>
          Sair
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
