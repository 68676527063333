import { useAPI } from "./useAPI";

export function useReservation() {
  const { api } = useAPI();

  const getServicesForDate = async (branch_id: number, date: string): Promise<Array<any>> => {
    return new Promise<Array<any>>((resolve) => {
      setTimeout(() => {
        resolve([
          {
            'id': 1,
            'name': 'Almoço',
            'times': ['12:00', '12:30', '13:00', '13:30']
          },
          {
            'id': 2,
            'name': 'Jantar',
            'times': ['12:00', '12:30', '13:00', '13:30']
          }
        ]);
      }, 1 * 1000);
    });
  }

  const getReasons = () => {
    return [
      { 'label': 'Aniversário', 'value': "1" },
      { 'label': 'Aniversário de Casamento', 'value': "2"},
      { 'label': 'Bodas', 'value': "3" },
      { 'label': 'Batizado', 'value': "4"},
      { 'label': 'Casamento', 'value': "5" },
      { 'label': 'Confraternização da Empresa', 'value': "6" },
      { 'label': 'Outros', 'value': "7" }
    ];
  }

  return {
    getServicesForDate,
    getReasons
  };
}
