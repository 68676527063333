import React, {useState} from "react";

function Success(props: any) {

    const [visible, setVisible] = useState(props.visible || true);

    return (
        <div
            className="swal2-container swal2-center swal2-backdrop-show"
            style={
            {
                'overflowY': 'auto',
                'display': (visible) ? 'grid' : 'none'
            }}>
            <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container"
                 className="swal2-popup swal2-modal swal2-icon-error swal2-show" role="dialog"
                 aria-live="assertive" aria-modal="true" style={{'display': 'grid'}}>
                <div className="swal2-icon swal2-success swal2-icon-show" style={{'display': 'flex'}}>
                    <div className="swal2-success-circular-line-left"
                         style={{'backgroundColor': 'rgb(255, 255, 255)'}}></div>
                    <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>
                    <div className="swal2-success-ring"></div>
                    <div className="swal2-success-fix" style={{'backgroundColor': 'rgb(255, 255, 255)'}}></div>
                    <div className="swal2-success-circular-line-right"
                         style={{'backgroundColor': 'rgb(255, 255, 255)'}}></div>
                </div>
                <div className="swal2-html-container" id="swal2-html-container" style={{'display': 'block'}}>{props.message}</div>
                <div className="swal2-actions" style={{'display': 'flex'}}>
                    <button
                        type="button"
                        className="swal2-confirm btn btn-primary"
                        aria-label=""
                        style={{'display': 'inline-block'}}
                        onClick={e => {
                            setVisible(false);
                            props.onClick(e);
                        }}
                    >Ok, entendi!
                    </button>
                </div>
            </div>
        </div>
);
}

export default Success;