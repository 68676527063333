import React, {FC, useEffect, useReducer} from "react";
import UserReducer from "../reducers/UserReducer";
import {BusinessBranchModel, BusinessModel} from "../models/BusinessModel";
import BusinessesReducer from "../reducers/BusinessesReducer";
import {DefaultBusiness} from "./DefaultData";

interface DataProps {
    businesses: Array<BusinessModel>
    business: BusinessModel
    loaded: boolean
    loading: boolean
    storedBusinessId: string | null
    loadBusinesses: () => Promise<boolean>
    setBusiness: (business: BusinessModel) => void
}
const DefaultData: DataProps = {
    businesses: [],
    business: DefaultBusiness,
    loaded: false,
    loading: false,
    storedBusinessId: window.localStorage.getItem('business_id'),
    loadBusinesses: () => {return new Promise<boolean>(resolve => resolve(true))},
    setBusiness: (business: BusinessModel) => {}
};
const BusinessesContext = React.createContext(DefaultData);
interface Props {
    businesses: Array<BusinessModel>
}
const BusinessesContextProvider: FC<Props> = ({children, businesses}) => {
    const loadBusinesses = async () => {
        dispatch({type: 'setLoading', loading: true});
        return new Promise((resolve) => {
            resolve(false);
            alert("BusinessesContextProvider.loadBusinesses function incomplete");
        });
    }
    const getBusinessById = (businesses: Array<BusinessModel>, business_id: string | null) => {
        for (let i in businesses) {
            if(businesses[i].id.toString() === business_id) {
                return businesses[i];
            }
        }

        return undefined;
    }
    const business = getBusinessById(businesses, window.localStorage.getItem('business_id'));
    const setBusiness = (business: BusinessModel) => {
        dispatch({type: 'setBusiness', business: business});
        window.localStorage.setItem('business_id', (business !== undefined) ? business.id.toString() : "-1");
    }


    const [data, dispatch] = useReducer(BusinessesReducer, {
        ...DefaultData,
        businesses: businesses,
        business: (business !== undefined) ? business : ((businesses !== undefined) ? businesses[0] : undefined),
        setBusiness: setBusiness
    });

    return (<BusinessesContext.Provider value={data}>{children}</BusinessesContext.Provider>);
}

export {BusinessesContextProvider, BusinessesContext};