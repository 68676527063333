import React, {FC} from "react";
import moment from "moment";
import { BusinessBranchModel } from "../../../models/BusinessModel";
import { Link } from "react-router-dom";

interface Props {
    business_branch: BusinessBranchModel
}
const CustomerSignupDataSuccess: FC<Props> = ({business_branch}) => {    
    
    return (
        <>
                
            <div className="card-body text-center pb-0">
                <div className="swal2-icon swal2-success swal2-icon-show" style={{'display': 'flex'}}><div className="swal2-success-circular-line-left" style={{'backgroundColor': 'rgb(255, 255, 255)'}}></div>
                    <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>
                    <div className="swal2-success-ring"></div> <div className="swal2-success-fix" style={{'backgroundColor': 'rgb(255, 255, 255)'}}></div>
                    <div className="swal2-success-circular-line-right" style={{'backgroundColor': 'rgb(255, 255, 255)'}}></div>
                </div>
            </div>
            
            <div className="card-body text-center pb-20">
                <h1 className="fs-2hx text-success mb-0 fw-bolder">Seu cadastro foi realizado com sucesso!</h1>
            </div>
            
            <div className="separator separator-dotted border-2 w-75 m-auto"></div>
            <label>
                <span className="section-ticket dots">
                    <span className="bg-light"></span>
                    <span className="bg-light"></span>
                </span>
            </label>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 text-center">
                        <Link to={`/perfil/${business_branch.slug}`} className="btn btn-lg btn-light py-5 w-30">
                            Acessar o Perfil<span className="svg-icon svg-icon-1 text-gray-400 m-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                </svg>
                            </span></Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomerSignupDataSuccess;