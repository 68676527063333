import React, {FC, useState} from "react";
import {BusinessBranchModel} from "../../../models/BusinessModel";
import {useStateList} from "../../../hooks/useStateList";
import { ReservationModel } from "../../../models/Reservation";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import ReservationAdminHeader from "./ReservationAdminHeader";
import { useAPI } from "../../../hooks/useAPI";
import Loading from "../../../components/Loading";

interface Props {
    business_branch: BusinessBranchModel
    reservation: ReservationModel
}

type Params = {
    id: string,
    token: string
}

const ReservationAdminOwner: FC<Props> = ({business_branch, reservation}) => {
    const {getStateShort} = useStateList();
    const {id, token} = useParams<Params>();
    const {api} = useAPI();
    const [loading, setLoading] = useState(false);   

    const cancelReservation = (e: any) => {
        e.preventDefault();
        if(window.confirm('Confirma o cancelamento dessa reserva?')) {
            setLoading(true);

            api()
            .put(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation/${id}/${token}/cancel`, {})
            .then((resp) => {
                setLoading(false);
                if(resp.data.success) {
                    alert('A reserva foi cancelada.');
                    window.location.reload();
                } else {
                    alert('Houve um erro, tente novamente.');
                }
            })
            .catch(() => {
                setLoading(false);
                alert('Houve um erro, tente novamente.')
            });
        }
    };

    return (
        <>
        {loading && (<Loading fullscreen={true} />)}
        <ReservationAdminHeader 
                    business_branch={business_branch} 
                    reservation={reservation} /> 
        <div className="container app-wrapper mw-500px">
                        
            <div className="card rounded-3 py-5 mt-5">
                <div className="card-body text-center">
                    {reservation?.status === 'CAN' && (
                        <>
                            <h1 className="fs-2hx text-danger fw-bolder">Agendamento Cancelado</h1>
                            <p className="text-gray-700 fs-5 fw-normal mb-0">Esse agendamento foi cancelado, por favor faça um novo.</p>
                        </>
                    )}

                    {reservation?.status === 'CAB' && (
                        <>
                            <h1 className="fs-2hx text-danger fw-bolder">Agendamento Cancelado</h1>
                            <p className="text-gray-700 fs-5 fw-normal mb-0">Infelizmente não será possível recebê-lo desta vez. Esperamos vê-lo em uma próxima ocasião.</p>
                        </>
                    )}

                    {reservation?.status === 'WAI' && (
                        <>
                            <h1 className="fs-2hx text-warning fw-bolder">Aguardando Confirmação!</h1>
                            <p className="text-gray-700 fs-5 fw-normal mb-0">Entraremos em contato com você após o agendamento ser aceito.</p>
                        </>
                    )}
                    
                    {reservation?.status === 'CON' && (
                        <>
                            <h1 className="fs-2hx text-success fw-bolder">Agendamento aceito!</h1>
                        </>
                    )}
                    
                </div>
                <div className="separator separator-dotted border-2 w-75 m-auto"></div>
                <label>
                    <span className="section-ticket dots">
                        <span className="bg-light"></span>
                        <span className="bg-light"></span>
                    </span>
                </label>
                {reservation?.status !== 'CAN' && (
                    <>
                        <div className="card-body text-center my-5">
                            <h2 className="fs-1 mb-5" style={{'textTransform': 'capitalize'}}>{reservation?.owner.name}</h2>
                            <h5><span style={{'textTransform': 'capitalize'}}>{moment(reservation?.datetime).format("dddd")}</span>{moment(reservation?.datetime).format(", DD [de] MMMM [de] YYYY")}</h5>
                            <h5 className="mb-5">{moment(reservation?.datetime).format("HH:mm")}h - {reservation?.person_number} Pessoas</h5>
                            <p className="text-gray-700 fs-5 fw-normal mb-10">{`${business_branch.address.street}, ${business_branch.address.number} - ${business_branch.address.district}`} <br /> {`${business_branch.address.city.name} - ${getStateShort(business_branch.address.city.state_id)}, ${business_branch.address.zipcode}`}</p>
                            {/*<div className="d-flex justify-content-center align-items-center">
                                <a href="agendamento-rsvp.html" className="btn btn-lg btn-primary">Confirmar Presença</a>
                                <button className="btn btn-lg btn-icon btn-light border border-gray-300 ms-3 shadow-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-overflow="true"><i className="bi bi-calendar3 text-mute fs-2"></i></button>
                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">
                                    <div className="separator mb-3 opacity-75"></div>
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">Google</a>
                                    </div>
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">Apple</a>
                                    </div>
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">Outlook</a>
                                    </div>
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">Yahoo</a>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                        <div className="separator separator-dotted border-2 w-75 m-auto"></div>
                        <label>
                            <span className="section-ticket dots">
                                <span className="bg-light"></span>
                                <span className="bg-light"></span>
                            </span>
                        </label>
                        <div className="card-body text-center">
                            <div className="d-grid">
                                <Link 
                                    to={`/reserva/${business_branch.slug}/${id}/${token}/guests`}
                                    className="btn btn-link">Ver Presenças Confirmadas</Link>
                                {/*<a href="agendamento-reagendar.html" className="btn btn-link">Reagendar</a>*/}
                                <a 
                                    onClick={cancelReservation}
                                    href="" className="btn btn-link">Cancelar Agendamento</a>
                                <Link to={`/reserva/${business_branch.slug}`} className="btn btn-link">Novo Agendamento</Link>
                            </div>
                        </div>
                    </>
                )}

                {reservation?.status === 'CAN' && (
                    <div className="card-body text-center">
                        <Link to={`/reserva/${business_branch.slug}`} className="btn btn-primary">Novo Agendamento</Link>
                    </div>
                )}
            </div>
            </div>
        </>
    );
}

export default ReservationAdminOwner;