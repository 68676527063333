/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, Redirect, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAPI} from "../../../hooks/useAPI";
import {useAuth} from "../../../hooks/useAuth";
import {UserContext} from "../../../providers/UserContextProvider";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato de e-mail inválido')
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Email é obrigatório'),
  password: Yup.string()
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Senha é obrigatório'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const {doLogin, api} = useAPI();
  const {setToken, setUser, setFeatures, setProfiles, setBusinesses, setBusinessBranch, setBusiness} = useAuth();
  const {hasAbility} = useAuth();
  let history = useHistory();
  let user_context = useContext(UserContext);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      if (!executeRecaptcha) {
        return;
      }
      setLoading(true);
      const token = await executeRecaptcha('login');

      doLogin(values.email, values.password, token).then(
          resp => {
            setLoading(false);
            if (resp.data.success) {
              setToken(resp.data.token);
              setUser(resp.data.user);
              setFeatures(resp.data.features);
              setProfiles(resp.data.profiles);
              setBusinesses(resp.data.businesses);

              if(resp.data.businesses.length > 0) {
                window.localStorage.setItem('business_branch_id', resp.data.businesses[0].branches[0].id);
                window.localStorage.setItem('business_id', resp.data.businesses[0].id);
              }

              // TODO: fix this WORKAROUND
              window.location.reload();
              return;

              user_context.loadUser().then((success) => {
                //setLoading(false);
                //setStatus(false);
                if(hasAbility('business-store')){
                  return history.push("/master/negocios")
                } else {
                  return history.push("/admin/dashboard")
                }
              });
            } else {
              setSubmitting(false)
              setStatus(resp.data.msg);
            }
          }
      ).catch(() => {
        setLoading(false)
        setSubmitting(false)
        setStatus('Desculpe, mas os dados informados não são válidos!')
      })
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Identifique-se</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          Digite seu e-mail e senha
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : ""}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex flex-stack mb-2'>
          {/* begin::Label */}
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Senha</label>
          {/* end::Label */}
          {/* begin::Link */}
          <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
          >
            Esqueceu a senha?
          </Link>
          {/* end::Link */}
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Entrar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Um momento por favor...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
