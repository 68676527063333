import React, {FC, useEffect, useReducer, useState} from "react";
import {UserModel} from "../models/UserModel";
import UserReducer from "../reducers/UserReducer";
import {useUser} from "../hooks/useUser";
import {useAuth} from "../hooks/useAuth";
import {useHistory, useLocation, useParams} from "react-router-dom";

const DefaultUser: UserModel = {
    id: -1,
    email: '',
    name: '',
    profile_id: -1,
    businesses: []
};

interface DataProps {
    user: UserModel
    loaded: boolean
    loading: boolean
    loadUser: () => Promise<boolean>
}
const DefaultData: DataProps = {
    user: DefaultUser,
    loaded: false,
    loading: false,
    loadUser: () => {return new Promise<boolean>(resolve => resolve(true))}
};
const UserContext = React.createContext(DefaultData);
const UserContextProvider: FC = ({children}) => {
    const user = useUser();
    const auth = useAuth();
    const history = useHistory();
    const location = useLocation();
    //const [slug, setSlug] = useState((location.pathname.split("/").length >= 3) ? location.pathname.split("/")[2] : "");
    let slug = "";

    const routes = [
        "perfil",
        "profile",
        "cardapios",
        "pesquisa",
        "reserva",
        "cadastro",
        "error",
        "admin",
        "master",
        "auth"
    ];

    const loadUser = async (slug: string) => {
        dispatch({type: 'setLoading', loading: true});
        return new Promise((resolve) => {
            user.get((auth.isAuth() ? auth.getUser().id : 'guest'), {slug: slug}).then((resp) => {
                if(!resp.error) {
                    dispatch({type: 'setUser', user: resp.data});
                    dispatch({type: 'setLoaded', loaded: true});
                } else {
                    if(resp.code === 403) {
                        auth.deleteToken();
                        history.push("/auth/login");
                    } else {
                        alert("Houve um erro, por favor recarregue a página.");
                    }
                }
                dispatch({type: 'setLoading', loading: false});
                resolve(!resp.error);
            });
        });
    }

    const [data, dispatch] = useReducer(UserReducer, {
        ...DefaultData,
        loadUser: loadUser
    });

    useEffect(() => {
        if(location.pathname === "/"){
            window.location.href = "http://nexpdigital.com.br";
            return;
        }


        if(routes.includes(location.pathname.split("/")[1])) {
            slug = location.pathname.split("/")[2];
        } else {
            slug = location.pathname.split("/")[1];
        }

        loadUser(slug).then((loaded) => {
            dispatch({type: 'setLoaded', loaded: loaded});
        });
    }, []);

    return (<UserContext.Provider value={data}>{children}</UserContext.Provider>);
}

export {UserContext, UserContextProvider};