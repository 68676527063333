import React, {FC, useEffect, useMemo, useReducer, useState} from 'react'
import {Footer} from './Footer'
import {Content} from './Content'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {AsideAdmin} from "./aside/AsideAdmin";
import {HeaderWrapperAdmin} from "./header/HeaderWrapperAdmin";
import {ToolbarAdmin} from "./ToolbarAdmin";
import {PageLink} from "../../../_metronic/layout/core";
import {FallbackView} from "../../partials/FallbackView";
import {PrivateRoutes} from "../../routing/PrivateRoutes";
import _ from 'lodash';
import {BusinessBranchContext, BusinessBranchContextProvider} from "../../providers/BusinessBranchContextProvider";
import {BusinessBranchesContextProvider} from "../../providers/BusinessBranchesContextProvider";
import {UserContext} from "../../providers/UserContextProvider";
import {BusinessesContext, BusinessesContextProvider} from "../../providers/BusinessesContextProvider";

const AdminLayout: React.FC = ({children}) => {

    const location = useLocation()
    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [location.key])

    useEffect(() => {
        document.body.classList.value= "header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed";
        let root = document.getElementById("root");
        if(root !== null) {
            root.classList.value = "d-flex flex-column flex-root";
        }
      }, [location.key]);

    const PrivateRoutesMemo = useMemo(() => {
        return (<PrivateRoutes />)
    }, [location.pathname]);


    return (
        <UserContext.Consumer>
            {({user}) => (
                <BusinessesContextProvider businesses={user.businesses}>
                    <BusinessesContext.Consumer>
                        {({business}) => (
                            <BusinessBranchesContextProvider business_branches={business?.branches}>

                                <>
                                    <div className='page d-flex flex-row flex-column-fluid'>

                                        <AsideAdmin/>
                                        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                                            <HeaderWrapperAdmin/>
                                            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                                                <ToolbarAdmin />
                                                <div className='post d-flex flex-column-fluid' id='kt_post'>
                                                    <Content>
                                                        {PrivateRoutesMemo}
                                                    </Content>
                                                </div>
                                            </div>
                                            <Footer/>
                                        </div>
                                    </div>
                                </>
                            </BusinessBranchesContextProvider>
                        )}
                    </BusinessesContext.Consumer>
                </BusinessesContextProvider>
            )}
        </UserContext.Consumer>
    )
}

export {AdminLayout}
