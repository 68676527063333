import {BusinessBranchModel, BusinessModel} from "../models/BusinessModel";
import {PageLink} from "../../_metronic/layout/core";

export function useAuth() {

    const PROFILE_MASTER = 1;
    const PROFILE_AGENCY = 2
    const PROFILE_BUSINESS = 3;
    const PROFILE_EDITOR = 4;

    const isAuth = () => {
        if (window.localStorage.getItem("token")) {
            return true;
        }
        return false;
    };

    const setToken = (token: string) => {
        window.localStorage.setItem("token", token);
    };

    const getToken = () => {
        return window.localStorage.getItem("token") || "";
    };

    const setUser = (user: any) => {
        window.localStorage.setItem("user", JSON.stringify(user));
    };

    const getUser = () => {
        let user = window.localStorage.getItem("user");
        if (user) {
            return JSON.parse(user);
        }
        return false;
    };

    const setFeatures = (features: any) => {
        window.localStorage.setItem("features", JSON.stringify(features));
    };

    const getFeatures = () => {
        let features = window.localStorage.getItem("features");
        if (features) {
            return JSON.parse(features);
        }
        return false;
    };

    const hasAbility = (ability: string) => {
        let features = getFeatures();
        for(let i in features){
            if(features[i] === ability){
                return true;
            }
        }

        return false;
    }

    const setProfiles = (profiles: any) => {
        window.localStorage.setItem("profiles", JSON.stringify(profiles));
    };

    const getProfiles = () => {
        let profiles = window.localStorage.getItem("profiles");
        if (profiles) {
            return JSON.parse(profiles);
        }
        return false;
    };

    const getProfile = (id: number) => {
        let profiles = getProfiles();
        for (let i in profiles) {
            if (profiles[i].id === id) {
                return profiles[i];
            }
        }

        return false;
    }

    const setBusinesses = (businesses: Array<BusinessModel>) => {
        window.localStorage.setItem("businesses", JSON.stringify(businesses));
    };

    const getBusinesses = () : Array<BusinessModel> | boolean => {
        let businesses = window.localStorage.getItem("businesses");
        if (businesses) {
            return JSON.parse(businesses);
        }

        return false;
    };

    const setBusiness = (business: BusinessModel) => {
        window.localStorage.setItem("business", JSON.stringify(business));
    };

    const getBusiness = () : BusinessModel | boolean => {
        let business = window.localStorage.getItem("business");
        if (business) {
            return JSON.parse(business);
        }

        return false;
    };

    const setBusinessBranch = (businessBranch: BusinessBranchModel) => {
        window.localStorage.setItem("businessBranch", JSON.stringify(businessBranch));
    };

    const getBusinessBranch = () : BusinessBranchModel | boolean => {
        let businessBranch = window.localStorage.getItem("businessBranch");
        if (businessBranch) {
            return JSON.parse(businessBranch);
        }

        return false;
    };

    const setBreadcrumbs = (breadcrumbs : Array<PageLink>) => {
        window.localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
    };

    const getBreadcrumbs = () : Array<PageLink> | boolean => {
        let breadcrumbs = window.localStorage.getItem("breadcrumbs");
        if (breadcrumbs) {
            return JSON.parse(breadcrumbs);
        }

        return false;
    };

    const setBreadcrumbsChanged = (changed: string) => {
        window.localStorage.setItem("breadcrumbsChanged", changed);
    };

    const getBreadcrumbsChanged = () : boolean => {
        return (window.localStorage.getItem("breadcrumbsChanged") === 'Y');
    };

    const setTitle = (title: string) => {
        window.localStorage.setItem("title", title);
    };

    const getTitle = () : string => {
        let title = window.localStorage.getItem("title");
        return (title !== null) ? title : '';
    };

    const deleteToken = () => {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("profiles");
        window.localStorage.removeItem("features");
    };

    return {
        isAuth,
        setToken,
        getToken,
        deleteToken,
        setUser,
        getUser,
        setFeatures,
        getFeatures,
        setProfiles,
        getProfiles,
        getProfile,
        hasAbility,
        setBusinesses,
        getBusinesses,
        setBusiness,
        getBusiness,
        setBusinessBranch,
        getBusinessBranch,
        setBreadcrumbsChanged,
        getBreadcrumbsChanged,
        getBreadcrumbs,
        setBreadcrumbs,
        getTitle,
        setTitle,
        PROFILE_MASTER,
        PROFILE_AGENCY,
        PROFILE_BUSINESS,
        PROFILE_EDITOR
    };
}
