import React, {createContext, FC, useReducer} from "react";
import {PageLink} from "../../_metronic/layout/core";
import {pageDataProviderReducer} from "./PageDataProviderReducer";
import _ from "lodash";

// Default data
interface PageDataProviderInterface {
    toolbar: {
        title: string,
        breadcrumbs: Array<PageLink>
        buttons: Array<any>
        bottom: boolean
    },
    updateToolbar: (toolbar: any) => void
}
const defaultData = {
    toolbar: {
        title: '',
        breadcrumbs: Array<PageLink>(),
        buttons: [],
        bottom: false
    },
    updateToolbar: (toolbar: any) => {}
}
const PageDataProviderContext = createContext<PageDataProviderInterface>(defaultData);

const PageDataProvider: FC = ({children}) => {
    const updateToolbar = (toolbar: any) => {
        if(!_.isEqual(pageData.toolbar, toolbar)){
            dispatch({type:'updateToolbar', toolbar: toolbar})
        }
    }

    const [pageData, dispatch] = useReducer(pageDataProviderReducer, {...defaultData, updateToolbar: updateToolbar});

    return (<PageDataProviderContext.Provider value={pageData}>{children}</PageDataProviderContext.Provider>);
}

export {PageDataProvider, PageDataProviderContext};