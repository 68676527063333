import React, {FC} from "react";
import clsx from "clsx";

interface Props {
    actual: number
    past: number
}
const ResearchOverviewBadge: FC<Props> = ({actual, past}) => {
    const variation = (actual - past);
    const variation_percent = (variation / past) * 100;
    const variation_final = (variation > 0 && variation_percent < 0) ? variation_percent * -1 : variation_percent;

    return (
        <>
            <span className={clsx("badge fs-base", {
                "badge-success": variation_final >= 0,
                "badge-danger": variation_final < 0,
            })}>
				<span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                    {variation_final >= 0 && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                             height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="13" y="6" width="13"
                                  height="2" rx="1"
                                  transform="rotate(90 13 6)" fill="black"/>
                            <path
                                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                fill="black"/>
                        </svg>
                    )}
                    {variation_final < 0 && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" />
                            <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor" />
                        </svg>
                    )}
                </span>{variation_final.toFixed(1)}%</span>
        </>
    );
}

export default ResearchOverviewBadge;