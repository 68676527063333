import React, {FC} from "react";
import {ApexOptions} from "apexcharts";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import 'moment/locale/pt-br';

interface Props {
    series: Array<any>
}

const ResearchOverviewTriesChart: FC<Props> = ({series}) => {
    var s = "#A1A5B7", r = "#EFF2F5", i = "#E4E6EF", t = 175;
    var baseColor = "#009EF7";

    var optionsApex: ApexOptions = {
       chart: {
            fontFamily: "inherit",
            type: "bar",
            height: t,
            toolbar: {
                show: !1
            }
        },
        plotOptions: {
            bar: {
                horizontal: !1,
                columnWidth: "50%",
                borderRadius: 4
            }
        },
        legend: {
            show: !1
        },
        dataLabels: {
            enabled: !1
        },
        stroke: {
            show: !0,
            width: 2,
            colors: ["transparent"]
        },
        xaxis: {
            categories: series.map((history) => {
                moment.locale('pt-br');
                return moment(history.date).format("DD MMM");
            }),
            axisBorder: {
                show: !1
            },
            axisTicks: {
                show: !1
            },
            labels: {
                style: {
                    colors: s,
                    fontSize: "12px"
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: s,
                    fontSize: "12px"
                }
            }
        },
        fill: {
            type: "solid"
        },
        states: {
            normal: {
                filter: {
                    type: "none",
                    value: 0
                }
            },
            hover: {
                filter: {
                    type: "none",
                    value: 0
                }
            },
            active: {
                allowMultipleDataPointsSelection: !1,
                filter: {
                    type: "none",
                    value: 0
                }
            }
        },
        tooltip: {
            style: {
                fontSize: "12px"
            },
            y: {
                formatter: function(e) {
                    return e.toString()
                }
            }
        },
        colors: [baseColor, i],
        grid: {
            padding: {
                top: 10
            },
            borderColor: r,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: !0
                }
            }
        }
    };

    return (
        <>
            <ReactApexChart
                options={optionsApex}
                type="bar"
                series={[{
                    name: "Respostas",
                    data: series.map((history) => {
                        return history.number_of_finished
                    })
                }, {
                    name: "Tentativas",
                    data: series.map((history) => {
                        return history.number_of_tries
                    })
                }]}
            />
        </>
    );
}

export default ResearchOverviewTriesChart;