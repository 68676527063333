import {useAPI} from "./useAPI";
import {BusinessResponseModel, ResponseModel} from "../models";

export function useUser() {
  const {api} = useAPI();

  const get = async (id: number, options: any= {}): Promise<ResponseModel> => {
    return new Promise<ResponseModel>((resolve)=> {
      api().get(`/api/user/${id}?${new URLSearchParams(options).toString()}`).then((resp) => {
        resolve(resp);
      }).catch((err) => {
        resolve({error: true, data: [], code: err.response.status});
      });
    });
  }

  return {
    get
  };
}
