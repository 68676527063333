import React, {FC, useEffect, useState} from "react";
import {BusinessBranchModel, BusinessModel} from "../../../models/BusinessModel";
import {useAuth} from "../../../hooks/useAuth";
import {useHistory} from "react-router-dom";
import {BusinessBranchesContext} from "../../../providers/BusinessBranchesContextProvider";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";

interface Props {
    business_branches?: Array<BusinessBranchModel>
    business_branch?: BusinessBranchModel
}

const BusinessBranchesMenu: FC<Props> = ({children, business_branch, business_branches}) => {

    const history = useHistory();
    return (
        <div className="menu-item">
            <div className="menu-content">
                {/*begin::Trigger*/}
                <button type="button" className="btn btn-link btn-color-gray-700" data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-start"
                        data-kt-menu="true"
                >
                    <div className="symbol-circle symbol symbol-30px pe-2">
                        <img
                            src={(business_branch?.avatar_url !== null) ? business_branch?.avatar_url : toAbsoluteUrl('/media/imgs/cardapio-item-nopicture.jpg')}
                            alt=""/>
                    </div>
                    <span className="d-inline-block text-truncate" style={{
                        'maxWidth': '120px',
                        'position': 'relative',
                        'top': '7px'
                    }}>{(business_branch !== undefined) ? business_branch.name : ''}</span>
                    <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                 fill="currentColor" className="bi bi-arrow-down-short"
                                 viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/>
                              </svg>
                        </span>
                </button>
                {/*end::Trigger*/}
                {/*begin::Menu*/}
                <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                    data-kt-menu="true">
                    <BusinessBranchesContext.Consumer>
                        {({setBusinessBranch}) => (
                            <>
                                {business_branches !== undefined ?
                                    business_branches.map((item, index) => {
                                        return (
                                            <div className="menu-item px-3" key={item.id}>
                                                <a href="" className="menu-link px-3" onClick={(e) => {
                                                    e.preventDefault();
                                                    setBusinessBranch(item);
                                                    window.location.href = "/admin/dashboard";
                                                }}>
                                                    {item.name}
                                                </a>
                                            </div>
                                        );
                                    })
                                    : ''
                                }
                            </>
                        )}
                    </BusinessBranchesContext.Consumer>
                </div>
                {/*end::Menu Estabelecimentos*/}
            </div>
        </div>
    );
}

export default BusinessBranchesMenu;