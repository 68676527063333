import React, {useState} from "react";

const Confirm = (props: any) => {

    const [visible, setVisible] = useState(props.visible || true);

    return (
        <div className="swal2-container swal2-center swal2-backdrop-show"
             style={
                 {
                     'overflowY': 'auto',
                     'display': (visible) ? 'grid' : 'none'
                 }}
        >
            <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container"
                 className="swal2-popup swal2-modal swal2-icon-warning swal2-show" tabIndex={-1} role="dialog"
                 aria-live="assertive" aria-modal="true" style={{'display': 'grid'}}>
                <div className="swal2-icon swal2-warning swal2-icon-show" style={{'display': 'flex'}}>
                    <div className="swal2-icon-content">!</div>
                </div>
                    <div className="swal2-html-container" id="swal2-html-container" style={{'display': 'block'}}>{props.message}</div>
                        <div className="swal2-actions" style={{'display': 'flex'}}>
                            <button type="button" className="swal2-confirm btn btn-light-primary" aria-label=""
                                    style={{'display': 'inline-block'}}
                                    onClick={props.onConfirm}
                            >Sim
                            </button>
                            <button type="button" className="swal2-deny btn btn-danger" aria-label=""
                                    style={{'display': 'inline-block'}}
                                    onClick={props.onClose}
                            >Não
                            </button>
                        </div>
            </div>
        </div>
    );
}

export default Confirm;