import React, {useEffect, useMemo} from 'react'
import {AsideMaster} from './aside/AsideMaster'
import {Footer} from './Footer'
import {HeaderWrapperMaster} from './header/HeaderWrapperMaster'
import {Content} from './Content'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {BusinessesContext, BusinessesContextProvider} from "../../providers/BusinessesContextProvider";
import {BusinessBranchesContextProvider} from "../../providers/BusinessBranchesContextProvider";
import {AsideAdmin} from "./aside/AsideAdmin";
import {HeaderWrapperAdmin} from "./header/HeaderWrapperAdmin";
import {ToolbarAdmin} from "./ToolbarAdmin";
import {UserContext} from "../../providers/UserContextProvider";
import {PrivateRoutes} from "../../routing/PrivateRoutes";

const MasterLayout: React.FC = ({children}) => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    document.body.classList.value= "header-fixed header-tablet-and-mobile-fixed aside-enabled aside-fixed";
  }, [location.key]);

  const PrivateRoutesMemo = useMemo(() => {
    return (<PrivateRoutes />)
  }, [location.pathname]);

  return (
      <UserContext.Consumer>
        {({user}) => (
            <BusinessesContextProvider businesses={user.businesses}>
              <BusinessesContext.Consumer>
                {({business}) => (
                    <BusinessBranchesContextProvider business_branches={business?.branches}>
                      <>
                        <div className='page d-flex flex-row flex-column-fluid'>
                          <AsideMaster />
                          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                            <HeaderWrapperMaster />

                            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                              <div className='post d-flex flex-column-fluid' id='kt_post'>
                                <Content>
                                  {PrivateRoutesMemo}
                                </Content>
                              </div>
                            </div>
                            <Footer />
                          </div>
                        </div>
                      </>
                    </BusinessBranchesContextProvider>
                )}
              </BusinessesContext.Consumer>
            </BusinessesContextProvider>
        )}
      </UserContext.Consumer>
  )
}

export {MasterLayout}
