import React from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../../_metronic/helpers'
import {useLayout} from '../../../../_metronic/layout/core'
import {OverlayTrigger, Tooltip} from "react-bootstrap-v5";

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  disabled?: boolean
  tooltip?: string
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
    disabled = false,
    tooltip
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config
    const htmlTitle = (
        <span
        className={clsx('menu-title', {'text-muted': disabled})}>{title}</span>
    );

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className={clsx('menu-icon', {'text-muted': disabled})}>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
          {(tooltip !== undefined) ?
              <OverlayTrigger
                  placement='top'
                  overlay={
                      <Tooltip id={`tolltip-to${to.replace('/', '-')}`}>
                          {tooltip}
                      </Tooltip>
                  }>
                  {htmlTitle}
              </OverlayTrigger>
              :
              htmlTitle
          }
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}
