import React, {useMemo, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {BusinessBranchesContextProvider} from "../../providers/BusinessBranchesContextProvider";
import {UserContext} from "../../providers/UserContextProvider";
import {BusinessesContext, BusinessesContextProvider} from "../../providers/BusinessesContextProvider";
import {SiteRoutes} from "../../routing/SiteRoutes";

import '../../css/custom-site.css';
import {FooterSite} from "./FooterSite";

const SiteLayout: React.FC = ({children}) => {

    const location = useLocation()
    const SiteRoutesMemo = useMemo(() => {
        return (<SiteRoutes />)
    }, [location.pathname]);

    useEffect(() => {
        document.body.classList.value= "header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed";
      }, [location.key]);

    return (
        <UserContext.Consumer>
            {({user}) => (
                <BusinessesContextProvider businesses={user.businesses}>
                    <BusinessesContext.Consumer>
                        {({business}) => (
                            <BusinessBranchesContextProvider business_branches={business?.branches}>
                                <>
                                    <div className="body-custom" id="body-custom">
                                        {SiteRoutesMemo}
                                        <FooterSite />
                                    </div>
                                </>
                            </BusinessBranchesContextProvider>
                        )}
                    </BusinessesContext.Consumer>
                </BusinessesContextProvider>
            )}
        </UserContext.Consumer>
    )
}

export {SiteLayout}
