import React, {useState} from "react";
import {useParams, Link} from "react-router-dom";
import {useAPI} from "../../../hooks/useAPI";
import * as Yup from "yup";
import {useFormik} from "formik";
import clsx from "clsx";

const initialValues = {
    password: '',
    password_match: ''
}

const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required('Senha é obrigatório'),
    password_match: Yup.string()
        .required('A confirmação de senha é obrigatório'),
})

let zxcvbn = require('zxcvbn');

function ResetPassword() {
    // @ts-ignore
    let {token} = useParams();
    const [loading, setLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const {api} = useAPI();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordScore, setPasswordScore] = useState({'score': 0});

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            if (passwordScore.score < 4) {
                setStatus("Sua senha é muito fraca, tente adicionar símbolos e letras.");
                setHasErrors(true);
                return;
            }
            if (values.password !== values.password_match) {
                setStatus("A confirmação de senha está incorreta.");
                setHasErrors(true);
                return;
            }

            setLoading(true);
            setHasErrors(undefined);
            api().post("/api/auth/new-password", {
                'new_password': values.password,
                'password_reset_token': token
            }).then(
                resp => {
                    setLoading(false);
                    if (resp.data.success) {
                        setHasErrors(false);
                    } else {
                        setStatus("Esse link de recuperação é inválido ou já expirou.");
                        setHasErrors(true);
                    }
                }
            ).catch(() => {
                setStatus("Houve um erro, tente novamente.");
                setHasErrors(true);
            });
        },
    })

    return (
        <>
            <form
                className="form w-100"
                id="kt_new_password_form"
                onSubmit={formik.handleSubmit}
            >
                <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">Digite a sua nova senha</h1>
                </div>
                {hasErrors === true && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>
                            {formik.status}
                        </div>
                    </div>
                )}

                {hasErrors === false && (
                    <div className='mb-10 bg-light-info p-8 rounded'>
                        <div className='text-info'>Sua senha foi alterada. Ir para <Link to={'/auth/login'}>Login</Link>.
                        </div>
                    </div>
                )}
                <div className="mb-10 fv-row" data-kt-password-meter="true">
                    <div className="mb-1">
                        <label className="form-label fw-bolder text-dark fs-6">Nova Senha</label>
                        <div className="position-relative mb-3">
                            <input
                                type={(passwordVisible) ? 'text' : 'password'}
                                placeholder=""
                                autoComplete='off'
                                {...formik.getFieldProps('password')}
                                onChange={e => {
                                    formik.handleChange(e);
                                    setPasswordScore(zxcvbn(e.target.value));
                                }}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid'
                                )}
                            />
                            <span
                                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                                data-kt-password-meter-control="visibility"
                                onClick={e => {
                                    setPasswordVisible(!passwordVisible);
                                }}
                            >
                                <i className={(!passwordVisible) ? 'bi bi-eye-slash fs-2' : 'bi bi-eye-slash fs-2 d-none'}></i>
                                <i className={(!passwordVisible) ? 'bi bi-eye fs-2 d-none' : 'bi bi-eye fs-2'}></i>
                            </span>
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="d-flex align-items-center mb-3"
                             data-kt-password-meter-control="highlight">
                            <div
                                className={'flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ' + ((passwordScore.score >= 1) ? 'active' : '')}></div>
                            <div
                                className={'flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ' + ((passwordScore.score >= 2) ? 'active' : '')}></div>
                            <div
                                className={'flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ' + ((passwordScore.score >= 3) ? 'active' : '')}></div>
                            <div
                                className={'flex-grow-1 bg-secondary bg-active-success rounded h-5px ' + ((passwordScore.score >= 4) ? 'active' : '')}></div>
                        </div>
                    </div>
                    <div className="text-muted">Use 8 ou mais caracteres com uma mistura de letras, números
                        e símbolos.
                    </div>
                </div>
                <div className="fv-row mb-10">
                    <label className="form-label fw-bolder text-dark fs-6">Confirme sua senha</label>
                    <input
                        type="password"
                        placeholder=""
                        autoComplete="off"
                        {...formik.getFieldProps('password_match')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.password_match && formik.errors.password_match},
                            {
                                'is-valid': formik.touched.password_match && !formik.errors.password_match,
                            }
                        )}
                    />
                    {formik.touched.password_match && formik.errors.password_match && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.password_match}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="text-center">
                    <button
                        type="submit"
                        id="kt_new_password_submit"
                        className="btn btn-lg btn-primary fw-bolder"
                        data-kt-indicator={(loading) ? "on" : "off"}
                    >
                        <span className="indicator-label">Enviar</span>
                        <span className="indicator-progress">Um momento por favor...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </>
    );
}

export default ResetPassword;
